// Libs
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import BlockingSpinner from 'components/blocking-spinner';
import { Select, Empty, Row, Col, Dropdown, Menu, Modal, Spin, Typography } from 'antd';
import HelpArticleModal from 'views/support/help-articles/HelpArticleModal';
import TopicModal from 'views/support/help-articles/TopicModal';
import AntIcon from 'components/ant-icon';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { UserPermissions } from 'types/permissions';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Utils
import history from 'utils/history';

// Icons
import {
  SearchOutlined,
  PlusOutlined,
  EllipsisOutlined,
  LoadingOutlined,
  CommentOutlined,
  EditOutlined,
} from '@ant-design/icons';

// Actions
import { setBreadcrumbs } from "store/UI/ActionCreators";

// Styles
import 'components/help-article/HelpArticle.scss';

const { Link } = Typography;

export interface ITopic {
  id: number;
  reference: string;
  title: string;
  description: string;
  icon: string;
  articles: IArticle[]
};

export interface IArticle {
  id: number;
  topic_ids: number[];
  status: 'DRAFT' | 'PUBLISHED';
  keywords: string[];
  title: string;
  description: string;
  video?: string;
  scribe: string;
  global: boolean;
  attachments: IAttachment[];
  updated_at: string;
};

export interface IAttachment {
  id: number | string;
  title: string;
  user_id?: number;
  file_path?: string;
  file_size?: number;
};

interface Props {
  client_id: number,
  permissions: UserPermissions;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  topics: ITopic[];
  searchResults: [];
  selectedTopicIndex: number;
  deleteId: number | null;
  isDeleting: boolean;
  showCreateModal: boolean;
  isFetchingTopics: boolean;
  isCreating: boolean;
  isSearching: boolean;
  showEditTopicModal: boolean;
};

const API: Api = new Api();

class HelpArticles extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    topics: [],
    searchResults: [],
    selectedTopicIndex: 0,
    deleteId: null,
    isDeleting: false,
    isFetchingTopics: false,
    isCreating: false,
    isSearching: false,
    showCreateModal: false,
    showEditTopicModal: false,
  };

  componentDidMount = () => {
    this.mounted = true;

    this.props.setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Support', path: '/support' },
      { title: 'Help Articles', path: '/support/help-articles' },
    ], false);

    this.fetchTopics(this.props.client_id);
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  fetchTopics = async (client_id: number) => {
    try {
      await new Promise((resolve) => this.setState({ isFetchingTopics: true }, () => resolve(null) ));
      const topics = await API.get(`client/${client_id}/support/article`);

      this.mounted && this.setState({
        topics: topics
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isFetchingTopics: false
      });
    }
  };

  handleSearch = async (client_id: number, value: string) => {
    try {
      await new Promise((resolve) => this.setState({ isSearching: true }, () => resolve(null) ));
      const searchResults = await API.get(`client/${client_id}/support/article`, {
        search: value
      });

      this.mounted && this.setState({
        searchResults: searchResults
      });

    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isSearching: false
      });
    }
  };

  getStructuredSearchResults = (searchResults: ITopic[]): any[] => {
    return searchResults.map((topic: ITopic) => {
      return {
        label: topic.title,
        options: _.isEmpty(topic.articles) ? [] : topic.articles.map((article: IArticle) => {
          return {
            id: article.id,
            label: article.title,
            value: article.id
          };
        })
      };
    });
  };

  scrollToTop = () => {
    const layout = document.querySelector('.Layout-content') as HTMLElement;
    if (layout) {
      layout.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  renderCreateModal = (client_id: number) => {
    return (
      <HelpArticleModal
        client_id={ client_id }
        onOk={ () => {
          this.setState({
            showCreateModal: false
          }, () => {
            this.fetchTopics(client_id);
          });
        } }
        onClose={ () => this.setState({ showCreateModal: false }) }
      />
    );
  };

  renderEditTopicModal = (topics: ITopic[], client_id: number) => {
    return (
      <TopicModal
        client_id={ client_id }
        topics={ topics }
        onOk={ () => {
          this.setState({
            showEditTopicModal: false
          }, () => {
            this.fetchTopics(client_id);
          });
        } }
        onClose={ () => this.setState({ showEditTopicModal: false }) }
      />
    );
  };

  renderDeleteDialog = (articleId: number) => {
    return (
      <Modal
        visible
        centered
        title={ 'Remove Article' }
        okButtonProps={{
          danger: true,
          loading: this.state.isDeleting,
        }}
        cancelButtonProps={{
          disabled: this.state.isDeleting,
        }}
        onOk={ async () => {
          try {

            await new Promise((resolve) => this.setState({ isDeleting: true }, () => resolve(null) ));
            await API.delete(`client/${this.props.client_id}/support/article/${articleId}`);
            this.fetchTopics(this.props.client_id);

          } catch(error) {
            Notification('error', `Failed to remove comment`);
            console.error('Error: ', error);
          } finally {
            this.mounted && this.setState({
              isDeleting: false,
              deleteId: null,
            });
          }
        } }
        onCancel={ () => this.setState({ deleteId: null }) }
      >
        <p>Are you sure you want to remove this article?</p>
      </Modal>
    );
  };

  render = () => {
    const { client_id, permissions } = this.props;
    const { topics, isFetchingTopics, selectedTopicIndex, searchResults } = this.state;
    const selectedTopic: ITopic | undefined = topics.find((__, index: number) => index === selectedTopicIndex);
    const _searchResults: any[] = !_.isEmpty(searchResults) ? this.getStructuredSearchResults(searchResults) : [];
    const canManage = !!permissions?.support_article_manage;

    if (isFetchingTopics) {
      return (
        <div className="d-f ai-c jc-c" style={{ height: 'calc(100vh - 80px)' }}>
          <BlockingSpinner isLoading />
        </div>
      );
    }

    if (_.isEmpty(topics) || !selectedTopic) {
      return (
        <div className="d-f ai-c jc-c" style={{ height: 'calc(100vh - 80px)' }}>
          <Empty description={ 'No articles found' } />
        </div>
      );
    }

    return (
      <div className="d-f fxd-c fx-1">
        <div className="Help-Article-Wrapper Layout-wrapper fx-1 pos-r">
          { canManage &&
            <div
              className="link pos-a d-f jc-c ai-c cur-p"
              style={{
                right: 50,
                top: 50,
                borderRadius: 20,
                padding: '5px 5px',
                borderWidth: 2,
                borderStyle: 'solid'
              }}
              onClick={ () => {
                this.setState({
                  showCreateModal: true
                });
              } }
            >
              <PlusOutlined
                className="Help-Article-Create-Button"
                style={{ fontSize: 20 }}
              />
            </div>
          }
          <div className="d-f jc-c bg-tab-grey pY-50">
            <div className="d-f fxd-c">
              <div className="d-f ai-c jc-c">
                <h1>What can we help you find today?</h1>
              </div>
              <div className="mT-10">
                <div className="d-f ai-c bg-white p-10 bd border-antd" style={{ borderRadius: 10, border: '1px solid black' }}>
                  <div className="d-if mL-5">
                    <SearchOutlined className="fsz-xl text-ant-default" />
                  </div>
                  <div className="d-if mL-10">
                    <Select
                      mode="multiple"
                      showSearch
                      tagRender={ () => (<></>) }
                      style={{ maxWidth: 600, minWidth: 500, fontSize: 18 }}
                      bordered={ false }
                      placeholder="Search by topic or keyword"
                      filterOption={ false }
                      notFoundContent={ _.isEmpty(_searchResults) ? 'No Matches' : undefined }
                      autoClearSearchValue
                      labelInValue
                      onInputKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.stopPropagation();
                        }
                      } }
                      onSearch={ _.debounce((value: string) => {
                        this.handleSearch(client_id, value);
                      }, 1000) }
                    >
                      { _searchResults.map((group: any, index: number) => (
                        <Select.OptGroup
                          key={ index }
                          label={ group.label }
                        >
                          { group.options.map((option: any, _index: number) => (
                            <Select.Option
                              key={ `${index}-${_index}--${option?.value}-${group?.reference}` }
                              value={ option.value }
                            >
                              <RouterLink
                                className="d-f text-dark"
                                to={ `help-articles/${option.id}` }
                                onClick={ () => this.scrollToTop() }
                              >
                                { option.label }
                              </RouterLink>
                            </Select.Option>
                          ) ) }
                        </Select.OptGroup>
                      ) ) }
                    </Select>
                  </div>
                  <div className="d-if mL-10" style={{ width: 30 }}>
                    { this.state.isSearching &&
                      <Spin indicator={ <LoadingOutlined style={{ fontSize: 24 }} spin /> } />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-f ai-c fxd-c fx-1 pT-40">
            <div className="d-f fxd-c fx-1 w-90p">
              <div className="d-f fxd-c fx-1" style={{ minHeight: 600 }}>
                <div className="d-f ai-c">
                  <span>
                    <h2 className="text-dark">Browse by topic</h2>
                  </span>
                  { canManage &&
                    <span className='mL-10 pB-3 link'>
                      <EditOutlined
                        style={{ fontSize: 20 }}
                        onClick={ () => {
                          this.setState({
                            showEditTopicModal: true
                          });
                        } }
                      />
                    </span>
                  }
                </div>
                <div className="d-f fx-1 mY-20">
                  <div className="Help-Article-Sidebar d-f bdR border-primary-lighten fxd-c">
                    { topics.map((topic: ITopic, index: number) => (
                      <div
                        key={ index }
                        className={ classNames('Help-Article-Sidebar-Item p-10 mR-20 cur-p mT-10', {
                          'bg-primary text-white': index === selectedTopicIndex
                        }) }
                        style={{ minWidth: 280, borderRadius: 10 }}
                        onClick={ () => {
                          this.setState({
                            selectedTopicIndex: index
                          }, () => {
                            this.scrollToTop();
                          });
                        } }
                      >
                        <div className='d-f ai-c'>
                          <span className="fsz-xl p-10">
                            <AntIcon type={ topic.icon } />
                          </span>
                          <span className="fsz-md mL-5">
                            { topic.title }
                          </span>
                        </div>
                      </div>
                    ) ) }
                  </div>
                  <div className="d-if fx-1 fxd-c mL-20 mT-10 text-dark">
                    <div className="d-f fx-1 fxd-c">
                      <div className="text-dark">
                        <div className="d-f fsz-lg fw-500">
                          { selectedTopic.title }
                        </div>
                        <div className="d-f fsz-md mT-5">
                          { selectedTopic.description }
                        </div>
                      </div>
                      <div className="d-f mT-50 fxd-c fx-1">
                        { _.isEmpty(selectedTopic.articles) ? (
                          <div className="d-f ai-c jc-c fx-1 fxd-c">
                            <Empty description={ 'No articles found' } />
                          </div>
                        ) : (
                          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            { selectedTopic.articles.map((article: IArticle) => (
                              <Col
                                key={ article.id }
                                span={ selectedTopic.articles.length / 24 }
                                style={{ marginBottom: 20 }}
                              >
                                <RouterLink
                                  key={ article.id }
                                  className="Help-Article-Article-Item d-if fxd-c p-30 pos-r"
                                  style={{ width: 280, height: 300, borderRadius: 10 }}
                                  to={ `help-articles/${article.id}` }
                                  onClick={ () => this.scrollToTop() }
                                >
                                  { canManage &&
                                    <div
                                      className="link pos-a d-f jc-c ai-c cur-p"
                                      style={{
                                        right: 20,
                                        top: 20,
                                      }}
                                      onClick={ (event: any) => {
                                        event.stopPropagation();
                                      } }
                                    >
                                      <Dropdown
                                        overlay={ () => (
                                          <Menu>
                                            <Menu.Item
                                              key={ 'edit' }
                                              onClick={ () => {
                                                history.push(`help-articles/${article.id}`, { edit: true });
                                              } }
                                            >
                                              { 'Edit' }
                                            </Menu.Item>
                                            <Menu.Item
                                              key={ 'delete' }
                                              onClick={ () => {
                                                this.setState({
                                                  deleteId: article.id
                                                });
                                              } }
                                            >
                                              { 'Delete' }
                                            </Menu.Item>
                                          </Menu>
                                        ) }
                                        trigger={ ['click'] }
                                      >
                                        <EllipsisOutlined style={{ fontSize: 20 }} />
                                      </Dropdown>
                                    </div>
                                  }
                                  { article.status === 'DRAFT' &&
                                    <div className="Help-Article-Status-Ribbon">{ article.status }</div>
                                  }
                                  <div className="fsz-def fw-500 text-dark">{ article.title }</div>
                                  <div className="fsz-sm mT-10">{ article.description }</div>
                                </RouterLink>
                              </Col>
                            ) ) }
                          </Row>
                        ) }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mY-40">
                <div className="d-f">
                  <h2 className="text-dark">Explore more</h2>
                </div>
                <div className="d-f fxd-r gp-30">
                  <div className="Help-Article-Extra-Item">
                    <RouterLink
                      className='d-f fxd-c color-grey-700 p-30'
                      to={ `support-tickets` }
                    >
                      <div className="fsz-def fw-500 text-dark">SUPPORT</div>
                      <div className="mT-5 fsz-xl"><CommentOutlined /></div>
                      <div className="fsz-sm mT-20 fxg-1">Couldn't find a answer to your question? Raise a ticket via our support portal and we'll be happy to help. Support is available Monday - Friday during office hours.</div>
                    </RouterLink>
                  </div>
                  { topics.filter((__: ITopic, index) => index < 2).map((topic: ITopic, index: number) => (
                    <div key={ index } className="Help-Article-Extra-Item">
                      <Link
                        className='d-f fxd-c color-grey-700 p-30'
                        onClick={ () => {
                          this.setState({
                            selectedTopicIndex: index
                          }, () => {
                            this.scrollToTop();
                          });
                        } }
                      >
                        <div className="fsz-def fw-500 text-dark">{ topic.title }</div>
                        <div className="fsz-xl mT-5"><AntIcon type={ topic.icon } /></div>
                        <div className="fsz-sm mT-20 fxg-1">{ topic.description }</div>
                      </Link>
                    </div>
                  ) ) }
                </div>
              </div>
            </div>
          </div>
        </div>
        { this.state.showEditTopicModal && this.renderEditTopicModal(topics, client_id) }
        { this.state.showCreateModal && this.renderCreateModal(client_id) }
        { this.state.deleteId && this.renderDeleteDialog(this.state.deleteId) }
      </div>
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpArticles);
