// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';

// Views
import NotificationView from 'views/notification/Notification';

// Components
import Badge, { BadgeType } from 'components/badge';
import { Menu, Dropdown, Modal, Input, Button, Checkbox, Empty, Spin } from 'antd';

// Actions
import { setBreadcrumbs } from "store/UI/ActionCreators";
import { setPrimarySidebarCollapsed } from 'store/UI/ActionCreators';

// Interfaces
import { getNotifications, deleteNotifications, markNotificationsAsRead, searchNotifications, NOTIFICATION_LOAD_COUNT } from 'store/Notification/Actions';
import { Breadcrumb } from 'store/UI/State.interface';
import NotificationState, { INotification }  from 'store/Notification/State.interface';
import AppState from 'store/AppState.interface';

// Services
import history from 'utils/history';
import { getFormatedDate } from 'services/settings';
import Notification from 'services/notification';

// Icons
import {
  DownOutlined,
  UpOutlined,
  CloseCircleFilled,
  SearchOutlined,
  DeleteOutlined,
  BorderOutlined,
  CheckSquareOutlined,
  LoadingOutlined,
  CheckOutlined,
} from '@ant-design/icons';

// Styles
import 'assets/styles/_notification.scss';

const { Search } = Input;

interface Props {
  client_id?: number;
  user_id?: number;
  notificationState: NotificationState,
  match: { params: Record<string, any> };
  getNotifications(items_per_page?: number): Promise<void>;
  searchNotifications(value: string): Promise<void>;
  deleteNotifications(notification_ids: number[]): Promise<void>;
  markNotificationsAsRead(notification_ids: number[]): Promise<void>;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setPrimarySidebarCollapsed(value: boolean): void;
};

interface State {
  activeNotification: number | null;
  searchString: string | null;
  selected: number[];
  isLoading: boolean;
  isSearching: boolean;
  isMarkingAsRead: boolean;
  showSearch: boolean;
  showDeleteDialog: boolean;
  showDropdownMenu: boolean;
};

class Notifications extends React.Component<Props, State> {

  mounted: boolean = false;
  notificationRefs: any[] = [];

  state: State = {
    activeNotification: Number(this.props.match.params.notification_id) || null,
    searchString: null,
    selected: [],
    isLoading: false,
    isSearching: false,
    isMarkingAsRead: false,
    showSearch: false,
    showDeleteDialog: false,
    showDropdownMenu: false,
  };

  componentDidMount = () => {
    const { getNotifications } = this.props;

    this.mounted = true;
    this.props.setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Notifications', path: '/notifications' }
    ], false);
    this.props.setPrimarySidebarCollapsed(true);

    getNotifications(NOTIFICATION_LOAD_COUNT).finally(() => {
      this.state.activeNotification && this.scrollToNotification(this.state.activeNotification);
    });
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.match.params.notification_id !== this.props.match.params.notification_id) {
      this.setState({
        activeNotification: Number(this.props.match.params.notification_id) || null
      }, () => {
        this.state.activeNotification && this.scrollToNotification(this.state.activeNotification);
      });
    }
  };

  componentWillUnmount = () => {
    this.props.setBreadcrumbs([], false);
    this.mounted = false;
  };

  scrollToNotification(id: number) {
    const node: HTMLElement = this.notificationRefs[id];
    if (!!node) {
      node.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }

  handleSearch = (value: string) => {
    const { searchNotifications } = this.props;
    this.setState({
      isSearching: true,
    }, () => {
      searchNotifications(value)
        .then((notifications: any) => {
          Notification('success', `Found ${notifications.length} notification${notifications.length === 1 ? '' : 's'}`);
        })
        .catch((err: string) => {
          Notification('error', `Failed to search`);
        })
        .finally(() => {
          this.mounted && this.setState({
            isSearching: false,
          });
        });
    });
  };

  handleClearSearch = () => {
    const { getNotifications } = this.props;
    const { searchString } = this.state;

    if (searchString) {
      this.setState({
        searchString: null,
        showSearch: false,
        isSearching: true,
        selected: [],
      }, () => {
        getNotifications().finally( () => {
          this.setState({
            isSearching: false
          });
        } );
      });
    } else {
      this.setState({
        showSearch: false
      });
    }

  };

  handleDelete = (ids: number[], suffix: string) => {
    const { getNotifications, deleteNotifications } = this.props;

    this.setState({
      isLoading: true,
    }, () => {
      deleteNotifications(ids)
        .then(() => {
          this.mounted && this.setState({
            selected: [],
          }, () => {
            Notification('success', `Sucessfully removed notification${suffix}`);
            getNotifications();
            history.push(`/notifications`);
          });
        })
        .catch((err: string) => {
          Notification('error', `Failed to delete notification${suffix}`);
        })
        .finally(() => {
          this.mounted && this.setState({
            isLoading: false,
            showDeleteDialog: false,
          });
        });
    });

  };

  renderDeleteDialog = () => {
    const { selected, isLoading } = this.state;
    const suffix = selected.length > 1 ? 's' : '';

    return (
      <Modal
        visible
        centered
        maskClosable={ !isLoading }
        closable={ !isLoading }
        title={ `Remove notification${suffix}` }
        onOk={() => this.handleDelete(selected, suffix) }
        onCancel={() => this.setState({
          isLoading: false,
          showDeleteDialog: false,
        }) }
        okButtonProps={{
          danger: true,
          disabled: isLoading,
          loading: isLoading,
        }}
        cancelButtonProps={{
          disabled: isLoading,
        }}
      >
        <p>Are you sure you want to delete <b>{ selected.length }</b>{ ` notification${suffix}` }?</p>
      </Modal>
    );
  };

  renderNotification = (user_id: number, notification_id: number | null) => {

    // Wait for the notificationState to load
    if (!this.props.notificationState.total) return <></>;

    return (
      <div className="h-100p">
        { !notification_id ? (
          <div className="d-f jc-c ai-c h-100p"><Empty description={ '' } /></div>
        ) : (
          <NotificationView notification_id={ notification_id } user_id={ user_id } />
        ) }
      </div>
    );
  };

  renderNotificationList = (hasMore: boolean, notifications: INotification[]) => {
    const { notificationState, getNotifications } = this.props;
    const { activeNotification, selected, isSearching, searchString } = this.state;

    if (isSearching) return <div className="d-f jc-c ai-c h-100p"><Spin indicator={ <LoadingOutlined style={{ fontSize: 24 }} spin /> } /></div>;

    if (_.isEmpty(notifications)) return <div className="d-f jc-c ai-c h-100p"><Empty description={ '' } /></div>;

    return (
      <InfiniteScroll
        loadMore={ () => !searchString && getNotifications(notificationState.notifications.length + NOTIFICATION_LOAD_COUNT) }
        hasMore={ hasMore }
        useWindow={ false }
        initialLoad={ false }
      >
        <ul className="lis-n pL-0 pB-160">
          { notifications.map((_notification: INotification) => {
            return (
              <li
                key={ _notification.id }
                ref={ node => (this.notificationRefs[_notification.id] = node) }
                className={ classNames('Notification-sidebar-item d-f ai-c lis-n bdB pY-10 pX-30 cur-p', {
                  'Notification-sidebar-item-active': _notification.id === activeNotification
                }) }
                onClick={ () => {
                  history.push(`/notifications/${_notification.id}`);
                } }
              >
                <span>
                  <Checkbox
                    checked={ selected.includes(_notification.id) }
                    onChange={event => {
                      this.setState({
                        selected: event.target.checked ? _.union([_notification.id], selected) : _.pull(selected, _notification.id)
                      });
                    } }
                    onClick={event => {
                      event.stopPropagation();
                    } }
                  />
                  <p style={{ color: 'transparent' }}>&nbsp;</p>
                </span>
                <span className="mL-20 w-90p">
                  <p className={ classNames('whs-nw ov-h tov-e', {
                      'fw-600': !_notification.read
                    }) }
                  >
                    { !_notification.read &&
                      <span className="d-ib bdrs-50p mR-10 bg-primary" style={{ width: 10, height: 10 }}></span>
                    }
                    { _notification.subject }
                  </p>
                  <p>
                    { _notification.type && <span className="mR-10"><Badge type={ _notification.type === 'PORT' ? BadgeType.Disabled : BadgeType.Default } text={ _notification.type.toUpperCase() } /></span> }
                    <span className="va-m">{ getFormatedDate(_notification.created_at, undefined, true) }</span>
                  </p>
                </span>
              </li>
            );
          } ) }
          { notificationState.isFetching &&
            <li key={ 'spinner' } className="d-f jc-c ai-c" style={{ height: 100 }}>
              <Spin indicator={ <LoadingOutlined style={{ fontSize: 24 }} spin /> } />
            </li>
          }
        </ul>
      </InfiniteScroll>
    );
  };

  render = () => {
    const { notificationState, user_id, markNotificationsAsRead } = this.props;
    const { searchString, activeNotification, selected, showSearch, showDeleteDialog, isSearching, isMarkingAsRead } = this.state;
    const notifications = notificationState.notifications;

    const activeNotificationIndex = notifications.findIndex(notification => notification.id === activeNotification);
    const hasMore =  !notificationState.isFetching && !!notificationState.total && notificationState.notifications.length !== notificationState.total;
    const isFirst = _.first(notifications)?.id === activeNotification;
    const isLast = _.last(notifications)?.id === activeNotification;

    if (!user_id) return <></>;

    return (
      <div className={ classNames('Notification bg-white ov-h h-100p', {
          'Notification--open': !!activeNotification
        }) }
      >
        <div className="Notification-sidebar d-f fxd-c">
          <div className="Notification-sidebar-header bdB bdR bg-white">
            <div className="d-f jc-sb">
              <div>
                <span className="va-m">
                  <Button
                    disabled={ _.isEmpty(notifications) }
                    icon={ !_.isEmpty(selected) ? (
                      <CheckSquareOutlined
                        onClick={ () => this.setState({
                          selected: []
                        }) }
                      />
                    ) : (
                      <BorderOutlined
                        style={{ fontSize: 13 }}
                        onClick={ () => this.setState({
                          selected: notifications.map(notification => notification.id)
                        }) }
                      />
                    ) }
                  />
                </span>
                { !_.isEmpty(selected) &&
                  <span className="va-m mL-10">
                    <Dropdown
                      trigger={ ['click'] }
                      placement="bottomRight"
                      overlay={ () => !_.isEmpty(selected) ? (
                        <Menu>
                          <Menu.Item
                            key="delete"
                            icon={ <DeleteOutlined /> }
                            onClick={ () => this.setState({ showDeleteDialog: true }) }>
                              Delete
                          </Menu.Item>
                          <Menu.Item
                            key="mark-as-read"
                            icon={ <CheckOutlined /> }
                            onClick={ async () => {
                              try {

                                await new Promise((resolve) => this.setState({ isMarkingAsRead: true }, () => resolve(null)));
                                await markNotificationsAsRead(selected);

                              } catch (error) {
                                console.error(`Error: ${error}`);
                              } finally {
                                this.mounted && this.setState({
                                  isMarkingAsRead: false,
                                });
                              }
                            } }>
                              Mark as Read
                          </Menu.Item>
                        </Menu>
                      ) : (
                        <></>
                      ) }>
                      <Button
                        disabled={ _.isEmpty(notifications) || isMarkingAsRead }
                        loading={ isMarkingAsRead }
                      >
                        { !_.isEmpty(selected) &&
                          <span>
                            <span>
                              {`${selected.length} Selected`}
                            </span>
                            <span className="mL-10">
                              <DownOutlined
                                onMouseEnter={ () => this.setState({ showDropdownMenu: true }) }
                                onMouseLeave={ () => this.setState({ showDropdownMenu: false }) }
                                onClick={ () => {} }
                              />
                            </span>
                          </span>
                        }
                      </Button>
                    </Dropdown>
                  </span>
                }
                <span className="va-m mL-5">
                  <Button
                    disabled={ _.isEmpty(notifications) }
                    onClick={ () => {
                      if (showSearch) {
                        this.handleClearSearch();
                      } else {
                        this.setState({ showSearch: true });
                      }
                    } }
                    icon={ <SearchOutlined /> }
                  />
                </span>
              </div>
              <div>
                <span className="va-m">
                  <Button
                    disabled={ !activeNotification || _.isEmpty(notifications) || isFirst }
                    icon={ <UpOutlined /> }
                    onClick={ () => {
                      if (!activeNotification) return;
                      history.push(`/notifications/${notifications[activeNotificationIndex - 1].id}`);
                    }}
                  />
                </span>
                <span className="va-m mL-5">
                  <Button
                    disabled={ !activeNotification || _.isEmpty(notifications) || isLast }
                    icon={ <DownOutlined /> }
                    onClick={ () => {
                      if (!activeNotification) return;
                      history.push(`/notifications/${notifications[activeNotificationIndex + 1].id}`);
                    }}
                  />
                </span>
              </div>
            </div>
            { showSearch &&
              <div className="u-fadeIn" style={{ marginTop: 17 }}>
                <Search
                  placeholder="Search"
                  loading={ !!searchString && isSearching }
                  onChange={ event => this.setState({ searchString: event.target.value }) }
                  onSearch={ (value: string) => this.handleSearch(value) }
                  value={ searchString || '' }
                  suffix={ searchString &&
                    <CloseCircleFilled
                      key='clear-search'
                      className="color-grey-400 mR-5 cur-p"
                      style={{ fontSize: '12px' }}
                      onClick={ () => this.handleClearSearch() }
                    />
                  }
                />
              </div>
            }
          </div>
          <div className="bdR h-100p ov-h">
            <div className="ovY-s h-100p">
              { this.renderNotificationList(hasMore, notifications) }
            </div>
          </div>
        </div>
        <div className="Notification-content">
          { this.renderNotification(user_id, activeNotification) }
        </div>
        { showDeleteDialog && this.renderDeleteDialog() }
      </div>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    user_id: store.UserState.user.id,
    notificationState: store.NotificationState,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    getNotifications: (items_per_page?: number) => dispatch(getNotifications(items_per_page)),
    searchNotifications: (value: string) => dispatch(searchNotifications(value)),
    deleteNotifications: (notification_ids: number[]) => dispatch(deleteNotifications(notification_ids)),
    markNotificationsAsRead: (notification_ids: number[]) => dispatch(markNotificationsAsRead(notification_ids)),
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
    setPrimarySidebarCollapsed: (value: boolean) => dispatch(setPrimarySidebarCollapsed(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);