// Libs
import React, { Component } from 'react';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import NumberFormat from 'react-number-format';
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage,
} from 'components/form/form-wrapper';
import { calculateFormula } from 'components/template-modal/TemplateTable';
import TemplateModal from 'components/template-modal/TemplateModal';

// Utils
import { findFirst } from 'utils/utils';

// Interfaces
import { RecordFormEntity } from 'types/entities';
import { ITemplate, ITemplateColumn } from 'components/template-modal/TemplateModal.interface';

interface Props {
  record: RecordFormEntity;
  field: any;
  clientId: number;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  numberFormat: any;
  originalValues: any;
  onChange(state: any): void;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  validate(field: FormField, column: string, value: string | number): string[];
  border?: boolean;
};

interface State {
  showTemplateModal: boolean;
};

class FinanceTemplateModal extends Component<Props, State> {

  state: State = {
    showTemplateModal: false,
  };

  componentDidUpdate = (prevProps: Props) => {
    if (!_.isEqual(prevProps.field, this.props.field)) {
      this.validate(this.props.field, this.props.originalValues);
    }
  };

  validate = (field: FormField, originalValues: any) => {
    const key = `${this.props.field.id}_${this.props.config.fieldIndex || 0}_finance_template_modal`;

    this.props.setFieldModifiedMessage(key, _.isEqual(field.values, originalValues) ? undefined : {
      id: this.props.field.id,
      cardinality: this.props.config.fieldIndex || 0,
      group: this.props.config.groupID,
      tab: this.props.config.tabID,
      order: this.props.config.elementIndex,
      content: {
        label: this.props.field.label,
        content: []
      },
      modified: { '': true }
    });
  };

  calculatePreview = (field: FormField) => {
    let total = 0;

    field.templates.forEach((template: ITemplate) => {
      // Use preview_source from each template, not from the field level
      const templateReference = template.preview_source?.template_reference;
      const columnReference = template.preview_source?.column_reference;

      if (templateReference && columnReference) {
        const column = template.columns.find(
          (column: ITemplateColumn) => column.reference === columnReference
        );

        field.values.forEach((row: any) => {
          if (column?.formula && row.template_id === template.id) {
            const _total = calculateFormula(template, null, row.values, column.formula);
            total += _total;
          }
        });
      }
    });

    return total;
  };

  getTemplateRows = (field: any) => {
    const collector: any[] = [];

    field.values.forEach((row: any, index: number) => {
      collector.push({
        id: row?.id || index++,
        key: row?.key || index++,
        template_id: row.template_id,
        values: row.values,
      });
    });

    return collector;
  };

  renderTemplateModal = (field: any, isDisabled: boolean = false) => {
    const template: ITemplate | undefined = field.templates.find(
      (template: ITemplate) =>
        template.preview_source?.template_reference === 'labour' ||
        template.preview_source?.template_reference === 'basic'
    );

    const column = template && template.columns.find(
      (column: ITemplateColumn) => column.type === 'coa'
    );

    const options = column && !_.isEmpty(column.config.options) ? column.config.options : [];
    const coa = field?.default_opex_coa_id && findFirst({ children: options }, 'children', { id: field.default_opex_coa_id });

    return (
      <TemplateModal
        coa={ coa }
        record={ this.props.record }
        templates={ field.templates }
        isDisabled={ isDisabled }
        defaultCurrency={ field?.currency || null }
        values={ this.getTemplateRows(field) }
        numberFormat={ this.props.numberFormat }
        onSave={ (rows: any) => this.props.onChange(rows) }
        onClose={ () => {
          this.setState({ showTemplateModal: false });
        } }
      />
    );
  };

  render = () => {
    const { field, config, border, fieldModifiedMessages, isDisabled } = this.props;
    const { showTemplateModal } = this.state;

    const key = `${field.id}_${config.fieldIndex || 0}_finance_template_modal`;
    const isModified = _.has(fieldModifiedMessages, key);
    const previewTotal = this.calculatePreview(field);

    return (
      <>
        <FieldWrapper
          id={ `${config.tabID}|${config.groupID}|${field.id}` }
          col={ config.fieldColSpan }
          label={ field.label }
          required={ field.config.required }
          border={ border }
          description={ !!field.description && field.description }
          isModified={ isModified }
          refreshOnChange={ !!field.config.refresh_on_change }
          versionChanged={ !!field.config.version_changed }
        >
          <NumberFormat
            { ...this.props.numberFormat }
            className="primaryColor cur-p"
            onClick={ () => {
              this.setState({
                showTemplateModal: true
              });
            } }
            fixedDecimalScale
            decimalScale={ 2 }
            value={ previewTotal }
            displayType={ 'text' }
          />
        </FieldWrapper>
        { showTemplateModal && this.renderTemplateModal(field, isDisabled) }
      </>
    );
  };
};

export default FinanceTemplateModal;
