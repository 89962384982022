// Libs
import React, { BaseSyntheticEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { Button, Input, Popconfirm, Select, Table, Tooltip, TreeSelect, Typography } from 'antd';

// Icons
import { DeleteOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { ReactComponent as WarningIcon } from 'assets/svg/warning-triangle.svg';

// Utils
import { isBlank, formatCostToNumber, isBlankNumeric, flattenSet } from 'utils/utils';
import { getFormatedNumber, getUserSetting } from 'services/settings';

// Interfaces
import {
  FormFieldConfig,
  FormFieldInfoBoxModifiedMessage,
  FormFieldInfoBoxErrorMessage
} from 'components/form/form-wrapper';
import {
  Field,
  FieldValue,
  ErrorState,
  Modified,
  CoaOption,
  FinanceTemplate,
  CostFieldColumnConfig,
  PresetValue
} from 'components/form/field/cost/Cost.interfaces';

// Styles
import './Cost.scss';

const { Option } = Select;
const { Text } = Typography;

const getBlankState = (numberFormat: any, currencyCode: string, defaultVat: number | undefined, unitCost: string | null = null, targetId: number | null = null, targetType: string | null = null, targetBundle: string | null = null, financeTemplateId: number | null = null): Partial<FieldValue> => {
  const uniqueKey: string = uuidv4();
  const defaultCostValue = formatCostToNumber('', numberFormat.thousandSeparator, numberFormat.decimalSeparator).toString();
  const defaultUnitCost = formatCostToNumber(unitCost || '', numberFormat.thousandSeparator, numberFormat.decimalSeparator).toString();

  return {
    key: uniqueKey,
    currency_code: currencyCode,
    id: null,
    target_id: targetId,
    target_type: targetType,
    target_bundle: targetBundle,
    finance_template_id: financeTemplateId,
    description: null,
    volume: defaultCostValue,
    unit_cost: defaultUnitCost,
    vat: defaultVat !== undefined ? defaultVat.toString() : defaultCostValue,
    net_total: defaultCostValue,
    total_value: defaultCostValue,
  };
};

const appendChildrenKeys = (children: any, values: any = [], disableAll: boolean = false) => {

  // Prevent nesting
  if (_.isEmpty(children)) return [];

  return children.map((childEntity: any) => {

    let disabled = disableAll;
    const existingValue: FieldValue | undefined = values.find((value: FieldValue) => {
      return `${value.target_type}_${value.target_id}` === `${childEntity.type}_${childEntity.id}`;
    });

    if (existingValue) {
      disabled = true;
    }

    return {
      'key': `${childEntity.type}_${childEntity.id}`,
      ...childEntity,
      'id': childEntity.id,
      'value': `${childEntity.type}_${childEntity.id}`,
      'title': childEntity.title,
      'disabled': disabled,
      'children': appendChildrenKeys(childEntity.children, values),
    };
  });
};

const generateServicesList = (data: CoaOption[] = [], values: FieldValue[] = []): CoaOption[] => {

  if (_.isEmpty(data)) {
    return [];
  }

  return data.map((entity: any) => {
    const existingValue: FieldValue | undefined = values.find((value: FieldValue) => {
      return `${value.target_type}_${value.target_id}` === `${entity.type}_${entity.id}`;
    });
    let disabled = false;

    if (existingValue) {
      disabled = true;
    }

    return {
      'key': `${entity.type}_${entity.id}`,
      ...entity,
      'id': entity.id,
      'value': `${entity.type}_${entity.id}`,
      'title': entity.title,
      'disabled': disabled,
      'children': appendChildrenKeys(entity.children, values),
    };
  });
};

const appendFinanceChildrenKeys = (children: any, values: any = []) => {

  // Prevent nesting
  if (_.isEmpty(children)) return [];

  const serviceChildren: any = [];

  children.forEach((childEntity: any) => {
    if (values.includes(childEntity.id)) {
      serviceChildren.push({
        'key': `${childEntity.type}_${childEntity.id}`,
        ...childEntity,
        'id': childEntity.id,
        'value': `${childEntity.type}_${childEntity.id}`,
        'title': childEntity.title,
        'children': appendFinanceChildrenKeys(childEntity.children, values),
      });
    }
  });

  return serviceChildren;
};

const generateFinanceTemplateServicesList = (data: CoaOption[], values: number[]): CoaOption[] => {
  const serviceTree: CoaOption[] = [];

  if (_.isEmpty(data) || _.isEmpty(values)) {
    return serviceTree;
  }

  data.forEach((entity: any) => {

    if (values.includes(entity.id)) {
      serviceTree.push({
        'key': `${entity.type}_${entity.id}`,
        ...entity,
        'id': entity.id,
        'value': `${entity.type}_${entity.id}`,
        'title': entity.title,
        'children': appendFinanceChildrenKeys(entity.children, values),
      });
    }
  });

  return serviceTree;
};

const generateServicesListFlatten = (data: CoaOption[] = [], flattenData: CoaOption[] = []): CoaOption[] => {

  data.forEach((entity: any) => {
    generateServicesListFlatten(entity.children, flattenData);

    flattenData.push({
      'key': `${entity.type}_${entity.id}`,
      ...entity,
      'children': null,
      'id': entity.id,
      'value': `${entity.type}_${entity.id}`,
      'title': entity.title
    });

  });

  return flattenData;
};

interface Props {
  isNew: boolean;
  numberFormat: any;
  originalValues: Field['values']
  field: Field;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: Record<string, FormFieldInfoBoxErrorMessage>;
  fieldModifiedMessages: Record<string, FormFieldInfoBoxModifiedMessage>;
  setFieldModifiedMessage(id: string, message?: FormFieldInfoBoxModifiedMessage): void;
  setFieldErrorMessage(id: string, message?: FormFieldInfoBoxErrorMessage): void;
  onValueChange(state: Field['values']): void;
  border?: boolean;
  height?: number;
};

interface State {
  currentRowTotal: {
    key: string | null;
    total_value: string | null;
    volume: string | null;
    unit_cost: string | null;
    vat: string | null;
    net_total: string | null;
    net_total_forecast: string | null;
  };
  tableHeight: number;
};

class CostField extends React.Component<Props, State> {

  component: any = null; // This is used to figure out when the component is rendered

  state: State = {
    currentRowTotal: {
      key: null,
      total_value: null,
      volume: null,
      unit_cost: null,
      vat: null,
      net_total: null,
      net_total_forecast: null
    },
    tableHeight: this.props.height || 0,
  };

  componentDidMount() {
    const { isNew, field, originalValues } = this.props;

    //initialise only if it is a new record
    if (isNew || _.isEmpty(originalValues)) {
      this.initialiseWithParentValues();
    } else {
      const isTotalsEnabled = this.isFieldColumnVisible('total');
      const isFinanceTemplateColumnVisible = this.isFieldColumnVisible('finance_template');

      this.validate(field.values, originalValues, isTotalsEnabled, isFinanceTemplateColumnVisible);
    }

    if (this.component && !this.props.height) {
      this.heightObserver();
    }
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, originalValues } = this.props;
    const isTotalEnabled = this.isFieldColumnVisible('total');
    const isFinanceTemplateColumnVisible = this.isFieldColumnVisible('finance_template');

    if (!_.isEqual(prevProps.field.preset_values, field.preset_values)) {
      this.initialiseWithParentValues();
    }

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(field.values, originalValues, isTotalEnabled, isFinanceTemplateColumnVisible);

      if (this.component && !this.props.height) {
        this.heightObserver();
      }

      this.scrollToBottom();
    }
  };

  heightObserver = () => {
    const height: number = document.getElementById('CostTable')?.offsetHeight || 0;

    if (this.state.tableHeight !== height) {
      this.setState({
        tableHeight: height - 50
      });
    }
  };

  scrollToBottom = () => {
    const table = document.querySelector('.CostField div.ant-table-body') as HTMLElement;
    if (table) {
      table.scrollTop = table.scrollHeight;
    }
  };

  initialiseWithParentValues = () => {
    const { preset_values, currency, default_vat } = this.props.field;
    const { numberFormat } = this.props;
    const currencyCode: string = currency?.code || '';
    const initValues: any[] = [];
    const defaultVat: number | undefined = default_vat;

    preset_values?.forEach((presetValue: PresetValue) => {
      if (currency !== undefined && !this.checkCoaIsPreset(presetValue)) {
        initValues.push(
          getBlankState(
            numberFormat,
            currencyCode,
            defaultVat,
            presetValue.unit_cost,
            presetValue.target_id,
            presetValue.target_type,
            presetValue.target_bundle,
            presetValue.finance_template_id
          )
        );
      }
    });

    if (!_.isEmpty(initValues)) {
      this.handleChange([...initValues]);
    }
  };

  getErrors = (values: FieldValue[], validateNumbers: boolean, numericKeys: Array<keyof FieldValue>, extraKeys: Array<keyof FieldValue> = []): ErrorState => {
    const errors: ErrorState = {};

    const keys: Array<keyof FieldValue> = ['target_id', ...extraKeys];

    const _validate = (value: FieldValue) => {
      return keys.filter((key: keyof FieldValue) => isBlank(value[key]));
    };

    const _validateNumber = (value: FieldValue) => {
      return numericKeys.filter((key: keyof FieldValue) => isBlankNumeric(value[key])) ;
    };

    values.forEach((value: FieldValue) => {
      const fieldKeys = _validate(value);
      if (!_.isEmpty(fieldKeys)) {
        errors[value.key] = fieldKeys;
      }
    });

    if (validateNumbers) {
      values.forEach((value: FieldValue) => {
        const fieldKeys = _validateNumber(value);
        if (!_.isEmpty(fieldKeys)) {
          errors[value.key] = fieldKeys;
        }
      });
    }

    return errors;
  };

  getModified = (values: FieldValue[], originalValues: FieldValue[], extraKeys: Array<keyof FieldValue> = []): Modified => {
    const modified: Modified = {};

    // check if values have changed
    if (!_.isEqual(values, originalValues)) {
      const fieldKeys: Array<keyof FieldValue> = ['target_id', 'total_value', 'description', 'net_total', 'net_total_forecast', ...extraKeys];

      const newStateKeys: string[] = values.map((value: FieldValue) => value.key);
      if (originalValues.some((_value: FieldValue) => !newStateKeys.includes(_value.key))) {
        modified['removed'] = null;
      }

      values.forEach((newValue: FieldValue) => {
        if (originalValues.some((_value: FieldValue) => _value.key === newValue.key)) {
          const oldValue = originalValues.find((_oldValue: FieldValue) => _oldValue.key === newValue.key);
          if (!_.isEqual(newValue, oldValue)) {
            (Object.keys(newValue) as Array<keyof FieldValue>)
              .filter((key) => fieldKeys.includes(key))
              .forEach((key) => {
                if (!oldValue) {
                  modified[newValue.key] = fieldKeys;
                } else if (!_.isEqual(newValue[key], oldValue[key])) {
                  if (!!modified[newValue.key] && !modified[newValue.key].includes(key)) {
                    modified[newValue.key] = modified[newValue.key].concat(key);
                  } else {
                    modified[newValue.key] = [key];
                  }
                }
              });
          }
        } else {
          modified[newValue.key] = fieldKeys;
        }
      });
    }

    return modified;
  };

  validateLineValue = (values: FieldValue[], errors: ErrorState): ErrorState => {
    values.forEach((value: FieldValue) => {
      const remainingValue: number = this.calculateRemainingValue(value);
      if (remainingValue < 0) {
        errors[value.key] = _.isEmpty(errors[value.key]) ? ['net_total', 'remaining_value'] : [...errors[value.key], 'net_total', 'remaining_value'];
      }
    });

    return errors;
  };

  validateTotalValue = (errors: ErrorState): void => {
    const { field, config, setFieldErrorMessage } = this.props;
    const totalAvailable = this.calculateTotalAvailable(field);
    const totalValidation = totalAvailable && getUserSetting('cost_field_validation') === 'on';

    let id: string = '';
    let label: string = '';
    let hasError: boolean = false;

    //check if the cost available is exceeded
    if (totalValidation !== null) {
      const totalCost: number = field.values.reduce((total: number, value: FieldValue) => total += parseFloat(value.net_total !== null ? value.net_total : '') || 0, 0) +
      field.preset_values?.reduce((total: number, presetValue: PresetValue) => total += parseFloat(presetValue.spent_value ?? '') || 0, 0);

      id = label = 'total_validation';

      if (totalAvailable && totalCost > totalAvailable) {
        errors['total_validation'] = ['total_validation'];

        hasError = true;
      }
    }

    const generalMessageInfo = {
      id: id,
      cardinality: config.fieldIndex || 0,
      group: config.groupID,
      tab: config.tabID,
      order: config.elementIndex,
      content: {
        label: label,
        content: [],
      },
    };

    const errorMessage = !hasError ? undefined : { ...generalMessageInfo, errors: errors };
    setFieldErrorMessage(id, errorMessage);
  };

  validate = (values: FieldValue[], originalValues: FieldValue[], validateNumbers: boolean, validateFinanceTemplate: boolean): void => {
    const { field, config, setFieldErrorMessage, setFieldModifiedMessage } = this.props;
    const numberKeys: Array<keyof FieldValue> = this.isFieldColumnVisible('total_value') ? ['total_value'] : [];
    const extraKeys: Array<keyof FieldValue> = validateFinanceTemplate ? ['finance_template_id'] : [];
    const errors: ErrorState = this.getErrors(values, validateNumbers, numberKeys, extraKeys);
    const modified: Modified = this.getModified(values, originalValues, extraKeys);

    if (getUserSetting('cost_field_validation') === 'line_by_line') {
      this.validateLineValue(values, errors);
    }

    const generalMessageInfo = {
      id: field.id,
      cardinality: config.fieldIndex || 0,
      group: config.groupID,
      tab: config.tabID,
      order: config.elementIndex,
      content: {
        label: field.label,
        content: [],
      },
    };

    const errorMessage = _.isEmpty(errors) ? undefined : { ...generalMessageInfo, errors: errors };
    const modifiedMessage = _.isEmpty(modified) ? undefined : { ...generalMessageInfo, modified: modified };
    setFieldErrorMessage(`${field.id}`, errorMessage);
    setFieldModifiedMessage(`${field.id}`, modifiedMessage);

    if (getUserSetting('cost_field_validation') === 'total_only') {
      this.validateTotalValue(errors);
    }
  };

  hasError = (fieldErrorMessages: Record<string, FormFieldInfoBoxErrorMessage>, rowKey: FieldValue['key'], fieldKey: keyof FieldValue): boolean => {
    const fieldId = this.props.field.id;
    return (
      _.has(fieldErrorMessages, [fieldId, 'errors', rowKey]) &&
      fieldErrorMessages[fieldId]['errors'][rowKey].includes(fieldKey)
    );
  };

  isModified = (fieldModifiedMessages: Record<string, FormFieldInfoBoxModifiedMessage>, rowKey: FieldValue['key'], fieldKey: keyof FieldValue): boolean => {
    const fieldId = this.props.field.id;
    return (
      _.has(fieldModifiedMessages, [fieldId, 'modified', rowKey]) &&
      fieldModifiedMessages[fieldId]['modified'][rowKey].includes(fieldKey)
    );
  };

  isFieldColumnVisible = (column: string): boolean => {
    const { field } = this.props;
    const fieldColumnsConfig: CostFieldColumnConfig[] | undefined = field.config.columns;

    const columnConfig: any[] = fieldColumnsConfig !== undefined && _.has(fieldColumnsConfig, column) ? _.get(fieldColumnsConfig, column) : [];
    if (!_.isEmpty(columnConfig)) {
      const isHidden = _.has(columnConfig, 'hide') ? _.get(columnConfig, 'hide') : false;

      return !isHidden;
    }

    return false;
  };

  isFieldColumnLocked = (column: string): boolean => {
    const { field } = this.props;
    const fieldColumnsConfig: CostFieldColumnConfig[] | undefined = field.config.columns;

    const columnConfig: any[] = fieldColumnsConfig !== undefined && _.has(fieldColumnsConfig, column) ? _.get(fieldColumnsConfig, column) : [];
    if (!_.isEmpty(columnConfig)) {
      return _.has(columnConfig, 'lock') ? _.get(columnConfig, 'lock') : false;
    }

    return false;
  };

  checkCoaIsPreset = (presetValue: PresetValue): FieldValue | undefined => {
    const values: FieldValue[] = this.props.field.values || [];

    return values.find(value => `${value.target_type}_${value.target_id}` === `${presetValue.target_type}_${presetValue.target_id}`);
  };

  calculateRowTotalValue = (
    numberFormat: any,
    calculateByUnitCost: boolean,
    key: string,
    netTotal: string | null,
    volume: string | null,
    unitCost: string | null,
    vat: string | null,
    totalForecast: string | null
  ): void => {
    const vatValue: number = formatCostToNumber(vat ?? '', numberFormat.thousandSeparator, numberFormat.decimalSeparator);
    const totalForecastValue: number = formatCostToNumber(totalForecast ?? '', numberFormat.thousandSeparator, numberFormat.decimalSeparator);
    let volumeValue: number = formatCostToNumber(volume ?? '', numberFormat.thousandSeparator, numberFormat.decimalSeparator);
    let unitCostValue: number = formatCostToNumber(unitCost ?? '', numberFormat.thousandSeparator, numberFormat.decimalSeparator);
    let netTotalValue: number = formatCostToNumber(netTotal ?? '', numberFormat.thousandSeparator, numberFormat.decimalSeparator);
    let totalValue: number = 0;

    if (calculateByUnitCost) {
      netTotalValue = unitCostValue * volumeValue;
    }

    if (vat !== null) {
      totalValue = netTotalValue + netTotalValue * (vatValue / 100);
    } else {
      totalValue = netTotalValue;
    }

    this.setState({
      ...this.state,
      currentRowTotal: {
        key: key,
        total_value: totalValue ? totalValue.toString() : '',
        volume: volume ? volume.toString() : '',
        unit_cost: unitCost ? unitCost.toString() : '',
        vat: vat ? vat.toString() : '',
        net_total: netTotalValue ? netTotalValue.toString() : '',
        net_total_forecast: totalForecastValue ? totalForecastValue.toString() : ''
      }
    });
  };

  calculateRemainingValue = (fieldValue: FieldValue): number => {
    const { field, numberFormat } = this.props;

    const coa = this.getCurrentCoa(field, fieldValue?.target_type || '', fieldValue?.target_id || 0);

    const spentValue = !!coa && coa?.spent_value ? coa.spent_value : 0;
    const formattedSpentValue = formatCostToNumber(spentValue?.toString() ?? '0', numberFormat.thousandSeparator, numberFormat.decimalSeparator);

    const availableValue = !!coa && coa?.available_value ? coa.available_value : 0;
    const formattedAvailableValue = formatCostToNumber(availableValue?.toString() ?? '0', numberFormat.thousandSeparator, numberFormat.decimalSeparator);

    const currentNetValue = formatCostToNumber(fieldValue?.net_total !== null ? fieldValue.net_total.toString() : '0', numberFormat.thousandSeparator, numberFormat.decimalSeparator);
    const totalSpentValue = formattedSpentValue + currentNetValue;

    return formattedAvailableValue - totalSpentValue;
  };

  calculateTotalAvailable = (field: Field): number | null => {
    return field.values?.reduce((total: number, row: FieldValue) => {
      const coa = this.getCurrentCoa(field, row?.target_type || '', row?.target_id || 0);
      const availableValue = !!coa && coa?.available_value ? coa.available_value : 0;
      const formattedAvailableValue = formatCostToNumber(availableValue?.toString() ?? '0', this.props.numberFormat.thousandSeparator, this.props.numberFormat.decimalSeparator);

      return total + (formattedAvailableValue || 0);
    }, 0);
  };

  calculateTotalSpent = (field: Field): number | null => {
    return field.values?.reduce((total: number, row: FieldValue) => {
      const coa = this.getCurrentCoa(field, row?.target_type || '', row?.target_id || 0);
      const spentValue = !!coa && coa?.spent_value ? coa.spent_value : 0;
      const formattedSpentValue = formatCostToNumber(spentValue?.toString() ?? '0', this.props.numberFormat.thousandSeparator, this.props.numberFormat.decimalSeparator);

      return total + (formattedSpentValue || 0);
    }, 0);
  };

  calculateFooterColspan = (ignoreAfter: string | null = null): number => {
    let colspan = 0;
    let borderColumnFound = false;

    ['service', 'finance_template', 'description', 'unit_cost', 'volume', 'net_total', 'vat'].forEach((column: string) => {
      if (ignoreAfter === column) {
        borderColumnFound = true;
      }

      if (!borderColumnFound && this.isFieldColumnVisible(column)) {
        colspan ++;
      }
    });

    return colspan ? colspan : 0;
  };

  calculateTotalFromKey = (data: any, key: string, numberFormat: any): number => {
    return data.reduce((acc: number, curr: any) => {
      const currFormatted = formatCostToNumber(curr[key] ?? '', numberFormat.thousandSeparator, numberFormat.decimalSeparator);
      return acc += currFormatted || 0;
    }, 0);
  };

  clearCurrentRowTotalValue = () => {
    this.setState({
      ...this.state,
      currentRowTotal: {
        key: null,
        total_value: null,
        volume: null,
        unit_cost: null,
        vat: null,
        net_total: null,
        net_total_forecast: null,
      }
    });
  };

  canAddNewRow = (): boolean => {
    const { field, isDisabled } = this.props;

    if (_.isEmpty(field.currency) || isDisabled) {
      return false;
    }

    return field.config.can_create;
  };

  getColumnConfigAttribute = (column: string, config: string): string => {
    const { field } = this.props;
    const fieldColumnsConfig: CostFieldColumnConfig[] | undefined = field.config.columns;

    const columnConfig: any[] = fieldColumnsConfig !== undefined && _.has(fieldColumnsConfig, column) ? _.get(fieldColumnsConfig, column) : [];
    if (!_.isEmpty(columnConfig)) {
      return _.has(columnConfig, config) ? _.get(columnConfig, config) : '';
    }

    return '';
  };

  handleChange = (values: FieldValue[]): void => {
    this.props.onValueChange(values);
  };

  modifyValues = (identifier: FieldValue['key'], values: FieldValue[], newValue: any, key: keyof FieldValue): FieldValue[] => {
    return values.map((value: FieldValue) => {
      if (value.key === identifier) {
        value = _.set(value, [key], newValue);
      }

      return {
        ...value,
      };
    });
  };

  delete = (identifier: FieldValue['key'], items: FieldValue[]): FieldValue[] => {
    return items.filter((value: FieldValue) => value.key !== identifier);
  };

  getCurrentCoa = (field: Field, targetType: string, targetId: number) => {
    return flattenSet(field.coa_options).find((coa: CoaOption) => coa.id === targetId && coa.type === targetType);
  };

  renderColumnTitle = (rows: string[], tooltip: string, required: boolean = false): JSX.Element => {
    return (
      <div className='d-f'>
        <div className='d-f fxd-c'>
          { rows.map((row, idx) => <span key={ `${row}_${idx}` }>{ row }</span>) }
        </div>
        { required && <span className="text-required mL-2 lh-1 fsz-md va-t">*</span> }
        { !!tooltip && (
          <Tooltip className="mL-5 pT-1" placement="top" title={ tooltip }>
            <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
          </Tooltip>
        ) }
      </div>
    );
  };

  renderTable = (field: Field): JSX.Element => {
    const { isDisabled, numberFormat, fieldErrorMessages, fieldModifiedMessages } = this.props;
    const { tableHeight } = this.state;

    const values: FieldValue[] = field.values || [];
    const currencyCode: string = field.currency !== undefined && !_.isEmpty(field.currency) ? field.currency?.code : '';
    const decimal: number | undefined = _.has(field, 'config.decimal') ? field.config.decimal : 2;
    const presetValues: PresetValue[] = field.preset_values !== undefined ? field.preset_values : [];
    const coaOptions: CoaOption[] = field.coa_options || [];
    const canDelete: boolean | undefined = !!field.config?.can_delete;

    const totalAvailable: number | null = this.calculateTotalAvailable(field);
    const totalSpent: number | null = this.calculateTotalSpent(field);

    const columns: any[] = [];

    this.isFieldColumnVisible('service') && columns.push({
      key: 'service',
      dataIndex: 'service',
      width: 200,
      title: this.renderColumnTitle(
        [this.getColumnConfigAttribute('service', 'title')],
        this.getColumnConfigAttribute('service', 'description'),
        true,
      ),
      render: (__: any, row: FieldValue) => {
        const hasErrors: boolean = this.hasError(fieldErrorMessages, row.key, 'target_id');
        const isModified: boolean = this.isModified(fieldModifiedMessages, row.key, 'target_id');
        const financeTemplates: FinanceTemplate[] = field?.finance_templates ? field.finance_templates : [];
        const financeTemplateId: number | undefined = row?.finance_template_id ? row.finance_template_id : undefined;
        const financeTemplate: FinanceTemplate | undefined = financeTemplates.find(_financeTemplate => _financeTemplate.id === financeTemplateId);
        let coaValue: any = row.target_type && row.target_id ? `${row.target_type}_${row.target_id}` : null;
        let coaServices: CoaOption[] = coaOptions;

        if (!!financeTemplate) {
          coaServices = generateFinanceTemplateServicesList(coaOptions, financeTemplate.coa_map);
        }
        else {
          coaServices = coaOptions ? generateServicesList(coaOptions, values.filter(value => `${value.target_type}_${value.target_id}` !== `${row.target_type}_${row.target_id}`)) : [];
        }

        const coaOptionsFlatten: CoaOption[] = coaServices ? generateServicesListFlatten(coaServices) : [];
        if (!_.isEmpty(presetValues)) {
          coaServices = coaOptionsFlatten.filter((opexCoaService: CoaOption) => presetValues.find(presetValue => `${opexCoaService.type}_${opexCoaService.id}` === `${presetValue.target_type}_${presetValue.target_id}`) !== undefined);
          coaServices = generateServicesList(coaServices, values.filter(value => `${value.target_type}_${value.target_id}` !== `${row.target_type}_${row.target_id}`));
        }

        if (!coaOptionsFlatten.find(_coaOption => coaValue === `${_coaOption.type}_${_coaOption.id}`)) {
          coaValue = null;
        }

        return (
          <TreeSelect
            showSearch
            allowClear
            dropdownMatchSelectWidth={ false }
            style={ { width: '100%' } }
            placeholder={ '-' }
            className={ classNames('Select-Field', {
              'Select-Field--has-error border-danger': !!hasErrors,
              'Select-Field--has-warning border-warning': isModified && !hasErrors,
            }) }
            value={ coaValue }
            treeData={ coaServices }
            disabled={ isDisabled || this.isFieldColumnLocked('service') }
            filterTreeNode={ (input: string, option: any) => {

              if (option) {
                const filteredInput = input.toLocaleLowerCase();
                const title = option.title && option.title.toLowerCase();

                if (title.includes(filteredInput)) {
                  return true;
                }
              }

              return false;
            } }
            onChange={ (selectedTarget: any) => {
              const targetId = selectedTarget ? selectedTarget.split('_').pop() : null;

              // It should not be possible to select the same service twice
              if (values.some((value: FieldValue) => value.target_id === targetId)) return;

              const coaServiceSelected: any = _.find(coaOptionsFlatten, { key: selectedTarget });

              let newValues = this.modifyValues(row.key, _.cloneDeep(values), coaServiceSelected?.id ? coaServiceSelected.id : null, 'target_id');
              newValues = this.modifyValues(row.key, _.cloneDeep(newValues), coaServiceSelected?.type ? coaServiceSelected.type : null, 'target_type');
              newValues = this.modifyValues(row.key, _.cloneDeep(newValues), coaServiceSelected?.bundle ? coaServiceSelected.bundle : null, 'target_bundle');

              this.handleChange(newValues);
            } }
          />
        );
      }
    });

    this.isFieldColumnVisible('finance_template') && columns.push({
      key: 'finance_template_id',
      dataIndex: 'finance_template_id',
      width: 200,
      title: this.renderColumnTitle(
        [this.getColumnConfigAttribute('finance_template', 'title')],
        this.getColumnConfigAttribute('finance_template', 'description'),
        true,
      ),
      render: (__: any, row: FieldValue) => {
        const financeTemplateId: number | undefined = row.finance_template_id ? row.finance_template_id : undefined;
        const hasErrors: boolean = this.hasError(fieldErrorMessages, row.key, 'finance_template_id');
        const isModified: boolean = this.isModified(fieldModifiedMessages, row.key, 'finance_template_id');
        const coaValue: any = row?.target_id;
        let financeTemplates: FinanceTemplate[] = !!field.finance_templates ? field.finance_templates : [];

        if (coaValue) {
          financeTemplates = financeTemplates.filter(_financeTemplate => _financeTemplate.coa_map.includes(coaValue));
        }

        return (
          <Select
            showSearch
            allowClear
            style={ { width: '100%' } }
            disabled={ isDisabled || this.isFieldColumnLocked('finance_template') }
            className={ classNames('Select-Field', {
              'Select-Field--has-error border-danger': !!hasErrors,
              'Select-Field--has-warning border-warning': isModified && !hasErrors,
            }) }
            placeholder={ '-' }
            filterOption={ (input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
            onChange={ (value: any) => {
              this.handleChange(this.modifyValues(row.key, _.cloneDeep(values), value, 'finance_template_id'));
            } }
            value={ financeTemplateId }
          >
            { financeTemplates.map((financeTemplate: FinanceTemplate) => (
              <Option key={ financeTemplate.id } value={ financeTemplate.id }>
                { financeTemplate.title }
              </Option>
            )) }
          </Select>
        );
      }
    });

    this.isFieldColumnVisible('description') && columns.push({
      key: 'description',
      dataIndex: 'description',
      width: 200,
      title: this.renderColumnTitle(
        [this.getColumnConfigAttribute('description', 'title')],
        this.getColumnConfigAttribute('description', 'description')
      ),
      render: (description: FieldValue['description'], row: FieldValue) => {
        const hasErrors: boolean = this.hasError(fieldErrorMessages, row.key, 'description');
        const isModified: boolean = this.isModified(fieldModifiedMessages, row.key, 'description');

        return (
          <Input
            className={ classNames('Field', {
              'Field--has-warning border-warning': isModified && hasErrors,
            }) }
            onBlur={ (event: BaseSyntheticEvent) => this.handleChange(this.modifyValues(row.key, _.cloneDeep(values), event.target.value, 'description')) }
            placeholder={ this.getColumnConfigAttribute('description', 'title') }
            required={ field.config.required }
            defaultValue={ description ?? '' }
            disabled={ isDisabled || this.isFieldColumnLocked('description') }
          />
        );
      },
      ellipsis: true,
    });

    this.isFieldColumnVisible('unit_cost') && columns.push({
      key: 'unit_cost',
      dataIndex: 'unit_cost',
      width: 130,
      type: 'number',
      title: this.renderColumnTitle(
        [`${this.getColumnConfigAttribute('unit_cost', 'title')} (${currencyCode})`],
        this.getColumnConfigAttribute('unit_cost', 'description'),
        true
      ),
      render: (unitCost: FieldValue['unit_cost'], row: FieldValue) => {
        const hasErrors: boolean = this.hasError(fieldErrorMessages, row.key, 'unit_cost');
        const isModified: boolean = this.isModified(fieldModifiedMessages, row.key, 'unit_cost');
        const modifiedUnitCost: string | null = this.state.currentRowTotal.unit_cost && this.state.currentRowTotal.key === row.key ? this.state.currentRowTotal.unit_cost : unitCost;

        return (
          <Input
            className={ classNames('Field pR-20 ta-r', {
              'Field--has-error border-danger': hasErrors,
              'Field--has-warning border-warning': isModified && !hasErrors,
            }) }
            required={ field.config.required }
            disabled={ isDisabled || this.isFieldColumnLocked('unit_cost') }
            value={ modifiedUnitCost || '' }
            onBlur={ (event: React.ChangeEvent<HTMLInputElement>) => {
              const value = formatCostToNumber(event.target.value, numberFormat.thousandSeparator, numberFormat.decimalSeparator);
              let modifiedValues = this.modifyValues(row.key, _.cloneDeep(values), value.toString(), 'unit_cost');
              modifiedValues = this.modifyValues(row.key, _.cloneDeep(modifiedValues), this.state.currentRowTotal.net_total || row.net_total, 'net_total');
              this.handleChange(this.modifyValues(row.key, _.cloneDeep(modifiedValues), this.state.currentRowTotal.total_value || row.total_value, 'total_value'));
              this.calculateRowTotalValue(numberFormat, this.isFieldColumnVisible('unit_cost'), row.key, row.net_total, row.volume, event.target.value, row.vat, row.net_total_forecast);
              this.clearCurrentRowTotalValue();
            } }
          />
        );
      },
      ellipsis: true,
    });

    this.isFieldColumnVisible('volume') && columns.push({
      key: 'volume',
      dataIndex: 'volume',
      width: 130,
      type: 'number',
      title: this.renderColumnTitle(
        [this.getColumnConfigAttribute('volume', 'title')],
        this.getColumnConfigAttribute('volume', 'description'),
        true
      ),
      render: (volume: FieldValue['volume'], row: FieldValue) => {
        const hasErrors: boolean = this.hasError(fieldErrorMessages, row.key, 'volume');
        const isModified: boolean = this.isModified(fieldModifiedMessages, row.key, 'volume');
        const volumeModified: string | null = this.state.currentRowTotal.volume && this.state.currentRowTotal.key === row.key ? this.state.currentRowTotal.volume : volume;

        return (
          <Input
            className={ classNames('Field pR-20 ta-r', {
              'Field--has-error border-danger': hasErrors,
              'Field--has-warning border-warning': isModified && !hasErrors,
            }) }
            required={ field.config.required }
            disabled={ isDisabled || this.isFieldColumnLocked('volume') }
            value={ volumeModified || '' }
            onBlur={ (event: React.ChangeEvent<HTMLInputElement>) => {
              const value = formatCostToNumber(event.target.value, numberFormat.thousandSeparator, numberFormat.decimalSeparator);
              let modifiedValues = this.modifyValues(row.key, _.cloneDeep(values), value.toString(), 'volume');
              modifiedValues = this.modifyValues(row.key, _.cloneDeep(modifiedValues), this.state.currentRowTotal.net_total || row.net_total, 'net_total');
              this.handleChange(this.modifyValues(row.key, _.cloneDeep(modifiedValues), this.state.currentRowTotal.total_value || row.total_value, 'total_value'));
              this.calculateRowTotalValue(numberFormat, this.isFieldColumnVisible('unit_cost'), row.key, row.net_total, event.target.value, row.unit_cost, row.vat, row.net_total_forecast);
              this.clearCurrentRowTotalValue();
            } }
          />
        );
      },
      ellipsis: true,
    });

    this.isFieldColumnVisible('net_total_forecast') && columns.push({
      key: 'net_total_forecast',
      dataIndex: 'net_total_forecast',
      width: 220,
      type: 'number',
      title: this.renderColumnTitle(
        [`${this.getColumnConfigAttribute('net_total_forecast', 'title')} (${currencyCode})`],
        this.getColumnConfigAttribute('net_total_forecast', 'description'),
        true
      ),
      onHeaderCell: () => {
        return { className: 'bg-tinted-yellow' };
      },
      onCell: () => {
        return { className: 'bg-tinted-yellow' };
      },
      render: (net_total_forecast: FieldValue['net_total_forecast'], row: FieldValue) => {
        const hasErrors: boolean = this.hasError(fieldErrorMessages, row.key, 'net_total_forecast');
        const isModified: boolean = this.isModified(fieldModifiedMessages, row.key, 'net_total_forecast');
        const netTotalForecastValueModified: string = this.state.currentRowTotal.net_total_forecast && this.state.currentRowTotal.key === row.key ? this.state.currentRowTotal.net_total_forecast : net_total_forecast || '';

        return (
          <div className="d-f">
            <NumberFormat
              { ...numberFormat }
              fixedDecimalScale={ !!decimal }
              decimalScale={ decimal }
              customInput={ Input }
              className={ classNames('Field pR-20 ta-r', {
                'Field--has-error border-danger': hasErrors,
                'Field--has-warning border-warning': isModified && !hasErrors,
              }) }
              required={ field.config.required }
              disabled={ isDisabled || this.isFieldColumnLocked('net_total_forecast') }
              allowNegative={ false }
              value={ netTotalForecastValueModified }
              onBlur={ (event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value === '' ? null : formatCostToNumber(event.target.value, numberFormat.thousandSeparator, numberFormat.decimalSeparator);
                const modifiedValues = this.modifyValues(row.key, _.cloneDeep(values), value !== null ? value.toString() : value, 'net_total_forecast');
                this.handleChange(modifiedValues);
                this.clearCurrentRowTotalValue();
              } }
            />
          </div>
        );
      },
      ellipsis: true,
    });

    this.isFieldColumnVisible('net_total') && columns.push({
      key: 'net_total',
      dataIndex: 'net_total',
      width: 220,
      type: 'number',
      title: this.renderColumnTitle(
        [`${this.getColumnConfigAttribute('net_total', 'title')} (${currencyCode})`],
        this.getColumnConfigAttribute('net_total', 'description'),
        true
      ),
      onHeaderCell: () => {
        return { className: 'bg-tinted-green' };
      },
      onCell: () => {
        return { className: 'bg-tinted-green' };
      },
      render: (net_total: FieldValue['net_total'], row: FieldValue) => {
        const hasErrors: boolean = this.hasError(fieldErrorMessages, row.key, 'net_total');
        const isModified: boolean = this.isModified(fieldModifiedMessages, row.key, 'net_total');
        const netTotalValueModified: string = this.state.currentRowTotal.net_total && this.state.currentRowTotal.key === row.key ? this.state.currentRowTotal.net_total : net_total || '';
        const remainingValue: number = this.calculateRemainingValue(row);
        const negativeRemainingValue: boolean = field.preset_values !== undefined && !_.isEmpty(field.preset_values) && remainingValue < 0;

        return (
          <div className="d-f">
            <NumberFormat
              { ...numberFormat }
              fixedDecimalScale={ !!decimal }
              decimalScale={ decimal }
              customInput={ Input }
              className={ classNames('Field pR-20 ta-r', {
                'Field--has-error border-danger': hasErrors,
                'Field--has-warning border-warning': isModified && !hasErrors,
                'Field--has-error border-problem': !hasErrors && negativeRemainingValue,
              }) }
              required={ field.config.required }
              disabled={ isDisabled || this.isFieldColumnLocked('net_total') }
              allowNegative={ false }
              value={ netTotalValueModified }
              onBlur={ (event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value === '' ? null : formatCostToNumber(event.target.value, numberFormat.thousandSeparator, numberFormat.decimalSeparator);
                const modifiedValues = this.modifyValues(row.key, _.cloneDeep(values), value !== null ? value.toString() : value, 'net_total');
                this.handleChange(modifiedValues);
                this.clearCurrentRowTotalValue();
              } }
            />
          </div>
        );
      },
      ellipsis: true,
    });

    this.isFieldColumnVisible('net_total_variance') && columns.push({
      key: 'net_total_variance',
      dataIndex: 'net_total_variance',
      width: 220,
      type: 'number',
      title: this.renderColumnTitle(
        [`${this.getColumnConfigAttribute('net_total_variance', 'title')} (${currencyCode})`],
        this.getColumnConfigAttribute('net_total_variance', 'description'),
        true
      ),
      onHeaderCell: () => {
        return { className: 'bg-tinted-cyan' };
      },
      onCell: () => {
        return { className: 'bg-tinted-cyan' };
      },
      render: (__: any, row: FieldValue) => {

        const totalForecast = formatCostToNumber(row?.net_total_forecast ?? '0', numberFormat.thousandSeparator, numberFormat.decimalSeparator);
        const totalActual = formatCostToNumber(row?.net_total ?? '', numberFormat.thousandSeparator, numberFormat.decimalSeparator);
        const totalVariance = totalForecast - totalActual;

        return (
          <div
            className={ classNames('ta-r', {
              'text-danger': totalVariance < 0,
              'text-success': totalVariance > 0,
            }) }
          >
            <span>{ getFormatedNumber(`${Math.abs(totalVariance)}`, null) }</span>
          </div>
        );
      },
      ellipsis: true,
    });

    this.isFieldColumnVisible('vat') && columns.push({
      key: 'vat',
      dataIndex: 'vat',
      width: 130,
      type: 'number',
      title: this.renderColumnTitle(
        [this.getColumnConfigAttribute('vat', 'title')],
        this.getColumnConfigAttribute('vat', 'description'),
        true
      ),
      render: (vat: FieldValue['vat'], row: FieldValue) => {
        const hasErrors: boolean = this.hasError(fieldErrorMessages, row.key, 'vat');
        const isModified: boolean = this.isModified(fieldModifiedMessages, row.key, 'vat');
        const vatModified: string | null = this.state.currentRowTotal.vat && this.state.currentRowTotal.key === row.key ? this.state.currentRowTotal.vat : vat;

        return (
          <NumberFormat
            { ...numberFormat }
            fixedDecimalScale={ !!decimal }
            decimalScale={ decimal }
            customInput={ Input }
            className={ classNames('Field pR-20 ta-r', {
              'Field--has-error border-danger': hasErrors,
              'Field--has-warning border-warning': isModified && !hasErrors,
            }) }
            required={ field.config.required }
            disabled={ isDisabled || this.isFieldColumnLocked('vat') }
            value={ vatModified || '' }
            onBlur={ (event: React.ChangeEvent<HTMLInputElement>) => {
              const value = formatCostToNumber(event.target.value ?? '', numberFormat.thousandSeparator, numberFormat.decimalSeparator);
              let modifiedValues = this.modifyValues(row.key, _.cloneDeep(values), value.toString(), 'vat');
              modifiedValues = this.modifyValues(row.key, _.cloneDeep(modifiedValues), this.state.currentRowTotal.net_total || row.net_total, 'net_total');
              this.handleChange(this.modifyValues(row.key, _.cloneDeep(modifiedValues), this.state.currentRowTotal.total_value || row.total_value, 'total_value'));
              this.calculateRowTotalValue(numberFormat, this.isFieldColumnVisible('unit_cost'), row.key, row.net_total, row.volume, row.unit_cost, event.target.value, row.net_total_forecast);
              this.clearCurrentRowTotalValue();
            } }
          />
        );
      },
      ellipsis: true,
    });

    this.isFieldColumnVisible('total') && columns.push({
      key: 'total_value',
      dataIndex: 'total_value',
      type: 'number',
      width: 180,
      title: this.renderColumnTitle(
        [`${this.getColumnConfigAttribute('total', 'title')} (${currencyCode})`],
        this.getColumnConfigAttribute('total', 'description'),
        true
      ),
      render: (totalValue: FieldValue['total_value'], row: FieldValue) => {
        const hasErrors: boolean = this.hasError(fieldErrorMessages, row.key, 'total_value');
        const isModified: boolean = this.isModified(fieldModifiedMessages, row.key, 'total_value');
        const totalValueModified: string | null = this.state.currentRowTotal.total_value && this.state.currentRowTotal.key === row.key ? this.state.currentRowTotal.total_value : totalValue;

        return (
          <div className="d-f">
            <NumberFormat
              { ...numberFormat }
              fixedDecimalScale={ !!decimal }
              decimalScale={ decimal }
              customInput={ Input }
              className={ classNames('Field pR-20 ta-r', {
                'Field--has-error border-danger': !!hasErrors,
                'Field--has-warning border-warning': isModified && !hasErrors,
              }) }
              required={ field.config.required }
              disabled={ isDisabled || this.isFieldColumnLocked('total') }
              allowNegative={ false }
              value={ totalValueModified }
              onBlur={ (event: React.ChangeEvent<HTMLInputElement>) => {
                const value: number = formatCostToNumber(event.target.value, numberFormat.thousandSeparator, numberFormat.decimalSeparator);
                this.handleChange(this.modifyValues(row.key, _.cloneDeep(values), value.toString(), 'total_value'));
              } }
            />
          </div>
        );
      },
      ellipsis: true,
    });

    this.isFieldColumnVisible('available_value') && columns.push({
      key: 'available_value',
      dataIndex: 'available_value',
      type: 'number',
      width: 200,
      title: this.renderColumnTitle(
        [`${this.getColumnConfigAttribute('available_value', 'title')} (${currencyCode})`],
        this.getColumnConfigAttribute('available_value', 'description')
      ),
      render: (__: any, row: FieldValue) => {
        const hasErrors: boolean = this.hasError(fieldErrorMessages, row.key, 'available_value');

        const coa = this.getCurrentCoa(field, row?.target_type || '', row?.target_id || 0);
        const availableValue = !!coa && coa?.available_value ? coa.available_value : 0;
        const formattedAvailableValue = formatCostToNumber(availableValue?.toString() ?? '0', numberFormat.thousandSeparator, numberFormat.decimalSeparator);

        return (
          <div className="d-f">
            <NumberFormat
              { ...numberFormat }
              fixedDecimalScale={ !!decimal }
              decimalScale={ decimal }
              customInput={ Input }
              className={ classNames('Field pR-20 ta-r', {
                'Field--has-error border-danger': !!hasErrors,
              }) }
              disabled={ isDisabled || this.isFieldColumnLocked('available_value') }
              required={ field.config.required }
              allowNegative
              value={ formattedAvailableValue }
            />
          </div>
        );
      },
      ellipsis: true,
    });

    this.isFieldColumnVisible('spent_value') && columns.push({
      key: 'spent_value',
      dataIndex: 'spent_value',
      type: 'number',
      width: 200,
      title: this.renderColumnTitle(
        [`${this.getColumnConfigAttribute('spent_value', 'title')} (${currencyCode})`],
        this.getColumnConfigAttribute('spent_value', 'description')
      ),
      render: (__: any, row: FieldValue) => {
        const hasErrors: boolean = this.hasError(fieldErrorMessages, row.key, 'spent_value');

        const coa = this.getCurrentCoa(field, row?.target_type || '', row?.target_id || 0);
        const spentValue = !!coa && coa?.spent_value ? coa.spent_value : 0;
        const formattedSpentValue = formatCostToNumber(spentValue?.toString() ?? '0', numberFormat.thousandSeparator, numberFormat.decimalSeparator);
        const formattedCurrentNetValue = formatCostToNumber(row.net_total !== null ? row.net_total.toString() : '0', numberFormat.thousandSeparator, numberFormat.decimalSeparator);

        return (
          <div className="d-f">
            <NumberFormat
              { ...numberFormat }
              fixedDecimalScale={ !!decimal }
              decimalScale={ decimal }
              customInput={ Input }
              className={ classNames('Field pR-20 ta-r', {
                'Field--has-error border-danger': !!hasErrors,
              }) }
              disabled={ isDisabled || this.isFieldColumnLocked('spent_value') }
              required={ field.config.required }
              allowNegative
              value={ formattedSpentValue + formattedCurrentNetValue}
            />
          </div>
        );
      },
      ellipsis: true,
    });

    this.isFieldColumnVisible('remaining_value') && columns.push({
      key: 'remaining_value',
      dataIndex: 'remaining_value',
      type: 'number',
      width: 200,
      title: this.renderColumnTitle(
        [`${this.getColumnConfigAttribute('remaining_value', 'title')} (${currencyCode})`],
        this.getColumnConfigAttribute('remaining_value', 'description')
      ),
      render: (__: any, row: FieldValue) => {
        const remainingValue: number = this.calculateRemainingValue(row);
        const negativeRemainingValue = remainingValue < 0;
        const hasErrors: boolean = this.hasError(fieldErrorMessages, row.key, 'remaining_value');
        const errorMessage: string = field?.preset_values_error ? field.preset_values_error : '';
        // Convert to string because of issue with NumberFormat component when pass number. It rounds to a higher value
        const remainingValueFormatted: string = remainingValue.toString();

        return (
          <div className="d-f">
            <NumberFormat
              { ...numberFormat }
              fixedDecimalScale={ !!decimal }
              decimalScale={ decimal }
              customInput={ Input }
              className={ classNames('Field pR-20 ta-r', {
                'Field--has-error border-danger': !!hasErrors,
                'Field--has-error border-problem': !hasErrors && negativeRemainingValue,
              }) }
              disabled={ isDisabled || this.isFieldColumnLocked('remaining_value') }
              required={ field.config.required }
              allowNegative
              value={ remainingValueFormatted }
            />
            { negativeRemainingValue &&
              <Tooltip
                overlayClassName="text-white"
                placement="topRight"
                title={ errorMessage }
              >
                <WarningIcon
                  className={ classNames('mL-5 mT-5', {
                    'text-danger': !!hasErrors,
                    'ant-text-danger': !hasErrors && negativeRemainingValue
                  }) }
                  height={ 20 }
                  width={ 20 }
                />
              </Tooltip>
            }
          </div>
        );
      },
      ellipsis: true,
    });

    columns.push({
      key: 'actions',
      title: this.canAddNewRow() ? (
        <Button
          className="ActionButton"
          onClick={ () => this.handleChange([
            ...field.values,
            getBlankState(
              numberFormat,
              currencyCode,
              field.default_vat
            )
          ]) }
          disabled={ !!field.template?.fixed || !currencyCode }
        >
          <PlusOutlined />
        </Button>
      ) : (
        <></>
      ),
      dataIndex: '',
      render: (row: FieldValue) => {
        return (
          <Popconfirm
            title={ 'Are you sure?' }
            icon={ <QuestionCircleOutlined style={ { color: 'red' } } /> }
            okButtonProps={ {
              danger: true,
              disabled: isDisabled,
            } }
            disabled={ isDisabled || !canDelete }
            placement="topRight"
            onConfirm={ () => this.handleChange(this.delete(row.key, values)) }
          >
            <Button
              style={ {
                padding: '4px 7px',
                width: '32px',
              } }
              disabled={ isDisabled || !canDelete }
            >
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        );
      },
      align: 'right' as 'right',
      fixed: 'right' as 'right',
      width: 60,
    });

    return (
      <div id="CostTable" className="h-100p" ref={ component => { this.component = component; } }>
        <Table
          className={ 'CostField' }
          dataSource={ values }
          columns={ columns }
          rowKey={ 'key' }
          size={ 'small' }
          scroll={ {
            x: 600,
            y: tableHeight || 400
          } }
          pagination={ false }
          sticky
          bordered
          summary={ (data: readonly FieldValue[]) => {
            return (
              <Table.Summary.Row>
                { columns.map((column: any, index: number) => {

                  if (column?.type !== 'number') {
                    return <td key={ index } />;
                  }

                  const netTotal = this.calculateTotalFromKey(data, 'net_total', numberFormat);
                  const forecastTotal = this.calculateTotalFromKey(data, 'net_total_forecast', numberFormat);
                  const varianceTotal = forecastTotal - netTotal;

                  const hasErrors = _.has(fieldErrorMessages, 'total_validation');

                  const _totalSpent: number = (totalSpent || 0) + netTotal;
                  const exceedMaxValue: boolean = totalAvailable !== null && totalAvailable < _totalSpent;
                  const remainingTotal: number = (totalAvailable || 0) - _totalSpent;

                  switch (column?.dataIndex) {
                    case 'unit_cost':
                      const unitCostTotal = this.calculateTotalFromKey(data, 'unit_cost', numberFormat);

                      return (
                        <Table.Summary.Cell key={ index } index={ index }>
                          <div className="ta-r fw-600">
                            <Text>
                              { `${getFormatedNumber(`${unitCostTotal}`)}` }
                            </Text>
                          </div>
                        </Table.Summary.Cell>
                      );
                    case 'volume':
                      const volumnTotal = this.calculateTotalFromKey(data, 'volume', numberFormat);

                      return (
                        <Table.Summary.Cell key={ index } index={ index }>
                          <div className="ta-r fw-600">
                            <Text>
                              { `${getFormatedNumber(`${volumnTotal}`)}` }
                            </Text>
                          </div>
                        </Table.Summary.Cell>
                      );
                    case 'net_total_forecast':
                      return (
                        <Table.Summary.Cell key={ index } index={ index }>
                          <div className="ta-r fw-600">
                            <Text>
                              { `${getFormatedNumber(`${forecastTotal}`)}` }
                            </Text>
                          </div>
                        </Table.Summary.Cell>
                      );
                    case 'net_total':
                      return (
                        <Table.Summary.Cell key={ index } index={ index }>
                          <div className="ta-r fw-600">
                            <Text>
                              { `${getFormatedNumber(`${netTotal}`)}` }
                            </Text>
                          </div>
                        </Table.Summary.Cell>
                      );
                    case 'net_total_variance':
                      return (
                        <Table.Summary.Cell key={ index } index={ index }>
                          <div className="ta-r fw-600">
                            <Text>{ `${getFormatedNumber(`${Math.abs(varianceTotal)}`)}` }</Text>
                          </div>
                        </Table.Summary.Cell>
                      );
                    case 'vat':
                      const vatTotal = this.calculateTotalFromKey(data, 'vat', numberFormat);
                      return (
                        <Table.Summary.Cell key={ index } index={ index }>
                          <div className="ta-r fw-600">
                            <Text>
                              { `${getFormatedNumber(`${vatTotal}`)}` }
                            </Text>
                          </div>
                        </Table.Summary.Cell>
                      );
                    case 'total_value':
                      const totalValue = this.calculateTotalFromKey(data, 'total_value', numberFormat);
                      return (
                        <Table.Summary.Cell key={ index } index={ index }>
                          <div className="ta-r fw-600">
                            <Text>
                              { `${getFormatedNumber(`${totalValue}`)}` }
                            </Text>
                          </div>
                        </Table.Summary.Cell>
                      );
                    case 'available_value':
                      return (
                        <Table.Summary.Cell key={ index } index={ index }>
                          <div className="ta-r fw-600">
                            <Text
                              className={ classNames({
                                'text-danger': !!hasErrors || !!exceedMaxValue,
                                'text-success': !hasErrors && !exceedMaxValue
                              }) }
                            >
                              { `${getFormatedNumber(`${totalAvailable}`)}` }
                            </Text>
                          </div>
                        </Table.Summary.Cell>
                      );
                    case 'spent_value':
                      return (
                        <Table.Summary.Cell key={ index } index={ index }>
                          <div className="ta-r fw-600">
                            <Tooltip
                              overlayClassName={ hasErrors ? 'text-white' : '' }
                              placement="topRight"
                              title={ `Must Equal or Less: ${getFormatedNumber(totalAvailable !== null ? totalAvailable.toString() : '')}` }
                            >
                              <Text
                                className={ classNames({
                                  'text-danger': !!hasErrors || !!exceedMaxValue,
                                  'text-success': !hasErrors && !exceedMaxValue
                                }) }
                              >
                                { `${getFormatedNumber(`${_totalSpent}`)}` }
                              </Text>
                              { (!!hasErrors || !!exceedMaxValue) && <WarningIcon className="text-danger mL-5 va-s" height={ 20 } width={ 20 } /> }
                            </Tooltip>
                          </div>
                        </Table.Summary.Cell>
                      );
                    case 'remaining_value':
                      return (
                        <Table.Summary.Cell key={ index } index={ index }>
                          <div className="ta-r fw-600">
                            <Text>
                              { `${getFormatedNumber(`${remainingTotal}`)}` }
                            </Text>
                          </div>
                        </Table.Summary.Cell>
                      );
                  }

                  return (
                    <Table.Summary.Cell key={ index } index={ index } />
                  );
                } ) }
              </Table.Summary.Row>
            );
          } }
        />
      </div>
    );
  };

  renderCards = (field: Field): JSX.Element => {

    const total = this.calculateTotalFromKey(field.values, 'net_total', this.props.numberFormat);
    const threshold = field?.threshold?.available || 0;
    const spent = field?.threshold?.spent || 0;
    const formattedSpent = spent + total;
    const billable = (threshold - spent) - total;
    const formattedBillable = billable > 0 ? 0 : billable;
    const billableColour = formattedBillable === 0 ? 'bg-tinted-green' : 'bg-tinted-red';
    const thresholdTooltip = field?.threshold?.threshold_tooltip;
    const spentTooltip = field?.threshold?.spent_tooltip;
    const billableTooltip = field?.threshold?.billable_tooltip;
    const currencySymbol = field.currency?.symbol || '';

    return (
      <div className='d-f gp-10 fxw-w'>
        <div className='d-f fx-1 bd fxd-c'>
          <div className='d-f fxd-c jc-c' style={{ height: 100, minWidth: 200 }}>
            <div className='d-f ac-c jc-c'>
              Comprehensive Threshold
              { !!thresholdTooltip && (
                <Tooltip className="mL-5 pT-1" placement="top" title={ thresholdTooltip }>
                  <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
                </Tooltip>
              ) }
            </div>
            <div className='d-f ac-c jc-c mT-5 fw-600' style={{ fontSize: 30 }}>{ getFormatedNumber(`${threshold}`, null, currencySymbol) }</div>
          </div>
        </div>
        <div className="d-f fx-1 bd fxd-c">
          <div className='d-f fxd-c jc-c' style={{ height: 100, minWidth: 200 }}>
            <div className='d-f ac-c jc-c'>
              Spent During Period
              { !!spentTooltip && (
                <Tooltip className="mL-5 pT-1" placement="top" title={ spentTooltip }>
                  <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
                </Tooltip>
              ) }
            </div>
            <div className='d-f ac-c jc-c mT-5 fw-600' style={{ fontSize: 30 }}>{ getFormatedNumber(`${formattedSpent}`, null, currencySymbol) }</div>
          </div>
        </div>
        <div className={ `d-f fx-1 bd fxd-c ${billableColour}` }>
          <div className='d-f fxd-c jc-c' style={{ height: 100, minWidth: 200 }}>
            <div className='d-f ac-c jc-c'>
              Billable
              { !!billableTooltip && (
                <Tooltip className="mL-5 pT-1" placement="top" title={ billableTooltip }>
                  <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
                </Tooltip>
              ) }
            </div>
            <div className='d-f ac-c jc-c mT-5 fw-600' style={{ fontSize: 30 }}>
              { getFormatedNumber(`${Math.abs(formattedBillable)}`, null, currencySymbol) }
            </div>
          </div>
        </div>
      </div>
    );
  };

  render = (): JSX.Element => {
    const { field, config, border } = this.props;
    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ config.fieldColSpan }
        label={ field.label }
        description={ !!field.description && field.description }
        required={ field.config.required }
        border={ border }
      >
        <FieldWrapper
          col={ 12 }
          border
        >
          <div>
            { this.renderTable(field) }
          </div>
          { !!field.threshold &&
            <div className='mT-10'>
              { this.renderCards(field) }
            </div>
          }
        </FieldWrapper>
      </FieldWrapper>
    );
  };
}

export default CostField;
