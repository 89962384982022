// @ts-nocheck

// Libs
import React, { Suspense } from 'react';

// Icons
import * as AntdIcons from '@ant-design/icons';

interface Props {
  type: string;
};

export default function AntIcon({
  type,
}: Props) {
  const AntdIcon = AntdIcons[type];

  if (!AntdIcon) {
    const Fallback = AntdIcons['QuestionOutlined'];
    return <Fallback />;
  }

  return <AntdIcon />;
};