// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, Link, NavLink } from 'react-router-dom';
import _ from 'lodash';

// Components
import { Breadcrumb as BreadcrumbComponent, Menu, Dropdown, Skeleton, Modal, Tooltip, Badge as AntBadge } from 'antd';
import { RestrictionWrapper, hasPermission } from 'components/restriction';
import Badge, { BadgeType, BadgeSize } from 'components/badge';
import OverlaySpinner from "components/overlay-spinner";

// Views
import ClientPortal from 'views/auth/ClientPortal';

// Services
import { logout } from 'services/auth';
import { Api } from 'services/api';
import Notification from 'services/notification';

// Interfaces
import AppState from 'store/AppState.interface';
import { UserEntity } from 'types/entities';
import { Breadcrumb } from 'store/UI/State.interface';
import NotificationState  from 'store/Notification/State.interface';
import ClientState from 'store/Client/State.interface';

// Icons
import Icon from '@ant-design/icons';
import { ReactComponent as AvatarIcon } from 'assets/svg/avatar.svg';
import { ReactComponent as SettingsIcon } from 'assets/svg/settings.svg';
import { ReactComponent as DashboardIcon } from 'assets/svg/dashboard.svg';
import { ReactComponent as ReportIcon } from 'assets/svg/report.svg';
import { ReactComponent as NotificationsIcon } from 'assets/svg/notifications.svg';
import { ReactComponent as HelpIcon } from 'assets/svg/help.svg';
import { ReactComponent as HomeIcon } from 'assets/svg/home.svg';

// Styles
import './Header.scss';

const API: Api = new Api();

interface Props {
  client: ClientState;
  router: RouteComponentProps;
  user: UserEntity;
  breadcrumbsLoading: any;
  breadcrumbs: Breadcrumb[];
  notificationState: NotificationState,
  logout(): void;
};

interface State {
  showCancelMasqueradingDialog: boolean;
  isCancelingMasquerading: boolean;
  showClientPortal: boolean;
  showHelpTooltip: boolean;
};

const renderBreadcrumbs = (breadcrumbs: Breadcrumb[], client: ClientState) => {

  const list: React.ReactElement[] = [];

  // Fallback
  if (_.isEmpty(breadcrumbs)) {
    return (
      <BreadcrumbComponent.Item><Link to='/'><HomeIcon className='link' style={{ verticalAlign: 'text-top' }} /></Link></BreadcrumbComponent.Item>
    );
  }

  breadcrumbs.forEach((breadcrumb: Breadcrumb, index: number) => {
    if (breadcrumb.path === '/') {
      list.push(
        <BreadcrumbComponent.Item key={ index }>
          <Link to='/'>
            <HomeIcon className='link' style={{ verticalAlign: 'text-top' }} />
            { client?.name &&
              <span className="mL-10">
                { client.name }
              </span>
            }
          </Link>
        </BreadcrumbComponent.Item>
      );
    } else if (breadcrumb.path === null) {
      list.push(
        <BreadcrumbComponent.Item key={index}>{ breadcrumb.title }</BreadcrumbComponent.Item>
      );
    } else if (breadcrumbs.length === index + 1) {
      breadcrumb.path && list.push(
        <BreadcrumbComponent.Item key={index}>{ breadcrumb.title }</BreadcrumbComponent.Item>
      );
    } else {
      breadcrumb.path && list.push(
        <BreadcrumbComponent.Item key={index}><Link to={breadcrumb.path}>{ breadcrumb.title }</Link></BreadcrumbComponent.Item>
      );
    }
  });

  return list;
};

class Header extends React.Component<Props, State> {

  state: State = {
    showCancelMasqueradingDialog: false,
    isCancelingMasquerading: false,
    showClientPortal: false,
    showHelpTooltip: false,
  };

  mounted: boolean = false;

  componentDidMount = async () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  renderCancelMasqueradingDialog = () => {
    const { client } = this.props;
    return (
      <Modal
        centered
        visible
        title={ 'Cancel Masquerading' }
        okButtonProps={{
          danger: true,
        }}
        onOk={ async () => {
          try {

            await new Promise((resolve) => this.setState({ isCancelingMasquerading: true, showCancelMasqueradingDialog: false }, () => resolve(null) ));
            await API.put(`/client/${client.client_id}/user/masquerade/stop`);
            window.location.reload();

          } catch (error) {
            Notification('error', '', 'Failed to cancel masquerading');
          } finally {
            this.setState({
              isCancelingMasquerading: false
            });
          }
        } }
        onCancel={() => this.setState({ showCancelMasqueradingDialog: false })}
      >
        <p>Are you sure you want to cancel masquerading?</p>
      </Modal>
    );
  };

  render = () => {
    const { user, breadcrumbs, breadcrumbsLoading, notificationState, logout, client } = this.props;
    const { showCancelMasqueradingDialog, isCancelingMasquerading, showClientPortal } = this.state;

    if (!user) return;

    const isMasquerading = user.is_masquerading || false;

    return (
      <>
        <header id="Header" className='Header'>
          <div className='Header-wrapper'>
            <div className='Header-breadcrumbs fxg-1 va-tp'>
              <Skeleton
                paragraph={ false }
                title={{
                  style: {
                    display: 'inline-block',
                    width: '90%'
                  }
                }}
                loading={ breadcrumbsLoading }
                active={ breadcrumbsLoading }
              >
                <BreadcrumbComponent>
                  { renderBreadcrumbs(breadcrumbs, client) }
                </BreadcrumbComponent>
              </Skeleton>
            </div>
            <div className='d-f'>
              <div className='Header-icons'>
                <RestrictionWrapper restricted={ !hasPermission(user.permissions, 'access_admin') && '' }>
                  <div className='Header-icon'>
                    <Tooltip title={ 'Admin' } placement="bottom">
                      <NavLink to='/admin' activeClassName='Header-icon-active'>
                        <Icon component={ SettingsIcon } style={{ fontSize: 17 }} />
                      </NavLink>
                    </Tooltip>
                  </div>
                </RestrictionWrapper>
                <RestrictionWrapper restricted={ !hasPermission(user.permissions, 'access_dashboards') && '' }>
                  <div className='Header-icon'>
                    <Tooltip title={ 'Dashboards' } placement="bottom">
                      <NavLink to='/dashboards' activeClassName='Header-icon-active'>
                        <Icon component={ DashboardIcon } style={{ fontSize: 17 }} />
                      </NavLink>
                    </Tooltip>
                  </div>
                </RestrictionWrapper>
                <RestrictionWrapper restricted={ !hasPermission(user.permissions, 'access_reporting') && '' }>
                  <div className='Header-icon'>
                    <Tooltip title={ 'Insights' } placement="bottom">
                      <NavLink
                        to='/insights'
                        activeClassName='Header-icon-active'
                        isActive={ (__: any, location: any) => {
                          return location.pathname.includes('/insights') || location.pathname.includes('/reports');
                        } }
                      >
                        <Icon component={ ReportIcon } style={{ fontSize: 17 }} />
                      </NavLink>
                    </Tooltip>
                  </div>
                </RestrictionWrapper>
                <div className='Header-icon'>
                  <Tooltip title={ 'Notifications' } placement="bottom">
                    <NavLink to='/notifications' className='pos-r' activeClassName='Header-icon-active'>
                      <AntBadge
                        dot={ !!notificationState.unread_total }
                      >
                        <Icon component={ NotificationsIcon } style={{ fontSize: 17 }} />
                      </AntBadge>
                    </NavLink>
                  </Tooltip>
                </div>
                <div className='Header-icon'>
                  <Tooltip
                    title={ 'Help' }
                    placement="bottom"
                  >
                    <NavLink to='/support' className='pos-r' activeClassName='Header-icon-active'>
                      <Icon component={ HelpIcon } style={{ fontSize: 17 }} />
                    </NavLink>
                  </Tooltip>
                </div>
              </div>
              <div className='Header-profile'>
                { isMasquerading ? (
                  <div
                    className='Header-profile-name Header-profile-name--is-masquerading cur-p'
                    onClick={ () => {
                      this.setState({
                        showCancelMasqueradingDialog: true
                      });
                    } }
                  >
                    <Badge className="Header-profile-badge" size={ BadgeSize.Small } type={ BadgeType.Default } text={ 'Masqueraded as' } />
                    <span>{ user.first_name} { user.last_name }</span>
                  </div>
                ) : (
                  <div className='Header-profile-name'>
                    <span>{ user.first_name} { user.last_name }</span>
                  </div>
                ) }
                <div className='d-if mL-10'>
                  <Dropdown
                    overlay={ (
                      <Menu>
                        <Menu.Item key="settings">
                          <Link to='/user/settings'>
                            <FormattedMessage id='general.settings' defaultMessage='Settings' />
                          </Link>
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item key="user-requests">
                          <Link to='/user-requests'>
                            User Requests
                          </Link>
                        </Menu.Item>
                        <Menu.Divider />
                        { process.env.REACT_APP_ENVIRONMENT !== 'training' &&
                          <>
                            <Menu.Item key="go-to-training">
                              <a href='https://app-training.pacs.org.uk'>
                                Go To Training
                              </a>
                            </Menu.Item>
                            <Menu.Divider />
                          </>
                        }
                        { process.env.REACT_APP_ENVIRONMENT === 'training' &&
                          <>
                            <Menu.Item key="go-to-live">
                              <a href='https://app.pacs.org.uk'>
                                Go To Live
                              </a>
                            </Menu.Item>
                            <Menu.Divider />
                          </>
                        }
                        { _.has(user, 'clients') && user.clients.length > 1 && !isMasquerading &&
                          <>
                            <Menu.Item key="switch_client" onClick={ () => this.setState({ showClientPortal: true }) }>
                              Switch Client
                            </Menu.Item>
                            <Menu.Divider />
                          </>
                        }
                        <Menu.Item key='logout' onClick={ () => logout() }>
                          <FormattedMessage id='general.logout' defaultMessage='Logout' />
                        </Menu.Item>
                      </Menu>
                    ) }
                    trigger={ ['click'] }
                  >
                    <Icon style={{ fontSize: '35px' }} component={ AvatarIcon } />
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </header>
        { showCancelMasqueradingDialog && this.renderCancelMasqueradingDialog() }
        { isCancelingMasquerading && <OverlaySpinner /> }
        { showClientPortal && <ClientPortal onClientSet={ () => this.setState({ showClientPortal: false }) } onCancel={ () => this.setState({ showClientPortal: false }) } /> }
      </>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client: store.ClientState,
    user: store.UserState.user,
    notificationState: store.NotificationState,
    breadcrumbs: store.UIState.breadcrumbs,
    breadcrumbsLoading: store.UIState.breadcrumbsLoading,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    logout: () => logout(),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
