// Libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

// Views
import HelpArticles from 'views/support/HelpArticles';
import HelpArticle from 'views/support/help-articles/HelpArticle';
import NotFound from 'views/NotFound';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';

// Actions
import {
  setSecondarySidebarRoutes,
  setBreadcrumbs,
  setPrimarySidebarCollapsed,
} from 'store/UI/ActionCreators';

interface Props {
  client_id: number;
  permissions: any;
  match: { params: Record<string, any> };
  location: any;
  isPrimarySidebarCollapsed: boolean;
  setPrimarySidebarCollapsed(value: boolean): void;
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
  setSecondarySidebarRoutes(routes: any[]): void;
};

class HelpArticleContainer extends React.Component<Props> {

  componentDidMount = () => {
    // Set default breadcrumbs
    this.props.setBreadcrumbs([
      { title: 'Home', path: '/' },
      { title: 'Support', path: '/support' },
      { title: 'Help Articles', path: '/support/help-articles' },
    ], false);

    if (!this.props.isPrimarySidebarCollapsed) {
      this.props.setPrimarySidebarCollapsed(true);
    };

    // Set default routes
    this.props.setSecondarySidebarRoutes([]);
  };

  componentWillUnmount = () => {
    this.props.setSecondarySidebarRoutes([]);
  };

  render = () => {
    return (
      <Switch>

        <Route exact path="/support/help-articles" component={ HelpArticles } />
        <Route exact path="/support/help-articles/:article_id" component={ HelpArticle } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };
};

// Make data available on props
const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
    isPrimarySidebarCollapsed: store.UIState.isPrimarySidebarCollapsed,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
    setPrimarySidebarCollapsed: (value: boolean) => dispatch(setPrimarySidebarCollapsed(value)),
    setSecondarySidebarRoutes: (value: any[]) => dispatch(setSecondarySidebarRoutes(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpArticleContainer);
