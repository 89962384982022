// @ts-nocheck

// Libs
import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import classNames from 'classnames';
import _ from 'lodash';

// Components
import FieldWrapper from 'components/form/field/field-wrapper';
import { Button, Modal, Typography, Tooltip } from 'antd';

// Icons
import { QuestionCircleOutlined } from '@ant-design/icons';

// Interfaces
import {
  FormField,
  FormFieldConfig,
  FormFieldInfoBoxErrorMessage,
  FormFieldInfoBoxModifiedMessage
} from 'components/form/form-wrapper';

// Styles
import 'react-quill/dist/quill.snow.css';
import './Editor.scss';

// Utils
import diff from 'utils/htmldiff';

const { Link } = Typography;

interface Props {
  field: FormField;
  onChange(
    field: FormField,
    value: any,
    config: FormFieldConfig,
    column?: string
  ): void;
  originalState: any;
  state: any;
  config: FormFieldConfig;
  isDisabled?: boolean;
  fieldErrorMessages: any;
  fieldModifiedMessages: any;
  setFieldModifiedMessage: (id: string, message?: FormFieldInfoBoxModifiedMessage) => void;
  setFieldErrorMessage: (id: string, message?: FormFieldInfoBoxErrorMessage) => void;
  validate: (field: FormField, column: string, value: string | number) => string[];
  border?: boolean;
};

interface State {
  showComparisonModal: boolean;
};

class Editor extends Component<Props, State> {

  state: State = {
    showComparisonModal: false
  };

  componentDidMount = () => {
    const { state } = this.props;
    this.validate(state);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { field, state } = this.props;

    if (!_.isEqual(prevProps.field, field)) {
      this.validate(state);
    }
  };

  componentWillUnmount = () => {
    const { field, originalState, state, config, onChange } = this.props;

    if (!_.isEqual(originalState, state)) {
      onChange(field, originalState, config);
      // Remove validations for this field
      this.validate(originalState, true);
    }

  };

  validate = (state: any, shouldClear = false) => {
    const { originalState } = this.props;

    const pastValue = originalState.value ? `${originalState.value}` : '';
    const newValue = state.value ? `${state.value}` : '';

    this.generateModifiedState(pastValue, newValue, 'value', shouldClear);
    this.generateErrorState(newValue, 'value', shouldClear);
  };

  generateModifiedState = (pastValue: string, newValue: string, columnKey: string = 'value', shouldClear = false) => {
    const { field, config, setFieldModifiedMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;

    if (!_.isEqual(pastValue, newValue) && !shouldClear) {
      const message: FormFieldInfoBoxModifiedMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: [],
        },
        modified: {}
      };

      setFieldModifiedMessage(key, message);
    } else {
      setFieldModifiedMessage(key);
    }
  };

  generateErrorState = (newValue: any, columnKey: string = 'value') => {
    const { field, config, validate, setFieldErrorMessage } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_${columnKey}`;
    let errors: any = [];

    const rawValue = newValue.replace(/<[^>]*>?/gm, ''); // filter out html-tags

    if (!!field.config.required && !rawValue) {
      errors.push('Cannot be empty');
    } else {
      errors.concat(validate(field, columnKey, newValue));
    }

    if (!_.isEmpty(errors) && !shouldClear) {
      const message: FormFieldInfoBoxErrorMessage = {
        id: id,
        cardinality: cardinality,
        group: config.groupID,
        tab: config.tabID,
        order: config.elementIndex,
        content: {
          label: field.label,
          content: []
        },
        errors: errors
      };

      setFieldErrorMessage(key, message);
    } else {
      setFieldErrorMessage(key);
    }
  };

  renderComparisonModal = (state: string) => {
    const { field, config, isDisabled } = this.props;
    const labelCurrent = !!field.compare && _.has(field.compare, 'label_current') ? field.compare.label_current : 'Current Value';
    const labelNew = !!field.compare && _.has(field.compare, 'label_new') ? field.compare.label_new : 'New Value';
    const helptext = !!field.compare && _.has(field.compare, 'helptext') ? field.compare.helptext : 'Please compare the current and new values below.';

    const footer = [(
      <Button
        key={ 'close' }
        onClick={ () => this.setState({ showComparisonModal: false }) }
      >
        Close
      </Button>
    )];

    if (!isDisabled) {
      footer.push((
        <Tooltip key={ 'update' } placement={ 'top' } title={ `This will discard the ${labelCurrent} and apply the ${labelNew}` }>
          <Button
            type="primary"
            onClick={ () => {
              this.props.onChange(field, field.compare?.new_value || '', config, 'value');
              this.setState({ showComparisonModal: false });
            } }
          >
            Apply Master Specification
          </Button>
        </Tooltip>
      ));
    }

    return (
      <Modal
        centered
        visible
        closable={ false }
        title={ 'Comparison' }
        style={{ minWidth: 1000 }}
        footer={ footer }
      >
        <div className="pB-20">
          <p>
            <QuestionCircleOutlined className="fsz-def mR-5" />
            { helptext }
          </p>
        </div>
        <div className="d-f jc-sb">
          <div style={{ width: '49%' }}>
            <div className="d-f">
              <span>{ labelCurrent }</span>
            </div>
            <div
              className="Editor Editor--disabled Editor--read-only d-b pY-5 pX-10 bd border-antd bdrs-2"
              dangerouslySetInnerHTML={{ __html: diff(field.compare?.new_value || '', _.has(state, 'value') ? state.value : '') }}
            />
          </div>
          <div style={{ width: '49%' }}>
            <div className="d-f">
              <span>{ labelNew }</span>
            </div>
            <div
              className="Editor Editor--disabled Editor--read-only d-b pY-5 pX-10 bd border-antd bdrs-2"
              dangerouslySetInnerHTML={{ __html: field.compare?.new_value }}
            />
          </div>
        </div>
      </Modal>
    );
  };

  renderCompareSection = () => {
    const { field, state } = this.props;
    const { showComparisonModal } = this.state;
    const newValue = field.compare?.new_value ? field.compare?.new_value.replace('<p><br></p>', '') : '';
    const originalValue = state?.value ? state.value.replace('<p><br></p>', '') : '';
    return (
      <>
        { !!field.compare &&
          <div className="d-f mT-5">
            <div dangerouslySetInnerHTML={{ __html: !_.isEqual(newValue, originalValue) ? field.compare.message : field.compare.match_message }}></div>
            { !_.isEqual(newValue, originalValue) &&
              <Link className="mL-5" onClick={ () => this.setState({ showComparisonModal: true }) }>
                Compare
              </Link>
            }
          </div>
        }
        { showComparisonModal && this.renderComparisonModal(state) }
      </>
    );
  };

  render = () => {
    const { field, config, isDisabled, border, fieldModifiedMessages, fieldErrorMessages, onChange, state } = this.props;

    const id = field.id;
    const cardinality = config.fieldIndex || 0;
    const key = `${id}_${cardinality}_value`;
    const errors = _.has(fieldErrorMessages, key) ? fieldErrorMessages[key].errors : [];
    const isModified = _.has(fieldModifiedMessages, key);
    const value: string | null = state.value ? `${state.value}` : '';

    if (!!isDisabled) {
      return (
        <FieldWrapper
          id={ `${config.tabID}|${config.groupID}|${field.id}` }
          col={ config.fieldColSpan }
          label={ field.label }
          required={ !!field.config.required }
          border={ border }
          versionChanged={ !!field.config.version_changed }
          description={ !!field.description && field.description }
        >
          <div
            className="Editor Editor--disabled Editor--read-only d-b pY-5 pX-10 bd border-antd bdrs-2"
            dangerouslySetInnerHTML={{ __html: value }}
          />
          { this.renderCompareSection() }
        </FieldWrapper>
      );
    }

    return (
      <FieldWrapper
        id={ `${config.tabID}|${config.groupID}|${field.id}` }
        col={ config.fieldColSpan }
        label={ field.label }
        errors={ errors }
        isModified={ isModified }
        required={ !!field.config.required }
        border={ border }
        versionChanged={ !!field.config.version_changed }
        description={ !!field.description && field.description }
      >
        <ReactQuill
          className={ classNames('Editor bd bdrs-2', {
            'border-antd': !isModified && _.isEmpty(errors),
            'border-warning': isModified && _.isEmpty(errors),
          }) }
          theme="snow"
          bounds={ '.Editor' }
          value={ value }
          onChange={ _.debounce((__, ___, source: string, editor) => {
            if (source === 'user') {
              onChange(field, editor.getHTML(), config, 'value');
            }
          }, 500) }
        />
        { this.renderCompareSection() }
      </FieldWrapper>
    );
  };
};

export default Editor;
