// Libs
import React from 'react';
import _ from 'lodash';

// Components
import { Input, Select, Checkbox, Tooltip, Upload } from 'antd';
import CoverModal from 'components/cover-modal';

// Interfaces
import { ITopic, IArticle, IAttachment } from 'views/support/HelpArticles';
import { UploadFile, RcFile } from 'antd/lib/upload/interface';

// Icons
import { QuestionCircleOutlined, InboxOutlined } from '@ant-design/icons';

// Services
import Notification from 'services/notification';
import { Api } from 'services/api';

// Styles
import 'components/help-article/HelpArticle.scss';

interface Props {
  client_id: number;
  article?: IArticle;
  onOk: () => void;
  onClose: () => void;
};

const API: Api = new Api();
const { Dragger } = Upload;

export default function HelpArticleModal({ client_id, article, onOk, onClose }: Props) {
  const [topics, setTopics] = React.useState<ITopic[]>([]);
  const [keywords, setKeywords] = React.useState<string[]>([]);
  const [isLoadingTopics, setIsLoadingTopics] = React.useState<boolean>(true);
  const [isLoadingKeywords, setIsLoadingKeywords] = React.useState<boolean>(true);
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [isCreating, setIsCreating] = React.useState<boolean>(false);
  const [placeholder, setPlaceholder] = React.useState<Partial<IArticle>>(article || {
    status: 'DRAFT',
    global: true,
  });
  const isNew = !article;

  React.useEffect(() => {
    fetchTopics(client_id);
    fetchKeywords(client_id);
  }, [client_id]);

  const fetchTopics = async (client_id: number) => {
    try {

      setIsLoadingTopics(true);
      const topics = await API.get(`client/${client_id}/support/topic`);
      setTopics(topics);

    } catch (error) {
      Notification('error', 'Failed to fetch topics', 'Failed');
    } finally {
      setIsLoadingTopics(false);
    }
  };

  const fetchKeywords = async (client_id: number) => {
    try {

      setIsLoadingKeywords(true);
      const keywords = await API.get(`client/${client_id}/support/tag`);
      setKeywords(keywords);

    } catch (error) {
      Notification('error', 'Failed to fetch keywords', 'Failed');
    } finally {
      setIsLoadingKeywords(false);
    }
  };

  return (
    <CoverModal
      cover
      middleContent={ isNew ? 'Create Article' : 'Edit Article' }
      isLoading={ isCreating }
      onClose={ () => onClose() }
      actions={ [
        {
          label: isNew ? 'Create' : 'Save',
          type: 'primary',
          isDisabled: !placeholder?.title || !placeholder.description,
          onClick: async () => {
            try {
              setIsCreating(true);

              if (isNew) {
                await API.post(`client/${client_id}/support/article`, {
                  data: {
                    title: placeholder.title,
                    description: placeholder.description,
                    scribe: placeholder.scribe,
                    topic_ids: !_.isEmpty(placeholder?.topic_ids) ? placeholder?.topic_ids : [],
                    keywords: placeholder?.keywords || [],
                    // video: placeholder?.video || null,
                    global: placeholder?.global || false,
                    status: placeholder?.status || 'DRAFT',
                    attachments: placeholder?.attachments || [],
                  }
                });
              } else {
                await API.put(`client/${client_id}/support/article/${article?.id}`, {
                  data: {
                    title: placeholder.title,
                    description: placeholder.description,
                    scribe: placeholder.scribe,
                    topic_ids: !_.isEmpty(placeholder?.topic_ids) ? placeholder?.topic_ids : [],
                    keywords: placeholder?.keywords || [],
                    // video: placeholder?.video || null,
                    global: placeholder?.global || false,
                    status: placeholder?.status || 'DRAFT',
                    attachments: placeholder?.attachments || [],
                  }
                });
              }

              Notification('success', `Article ${isNew ? 'created' : 'edited'}`);
              onOk();
            } catch (error) {
              console.error('Error: ', error);
              Notification('error', `Failed to ${isNew ? 'create' : 'edit'} article`);
            }
          },
        }
      ] }
    >
      <div className="d-f jc-c ai-c h-100p">
        <div className="d-f fxd-c h-100p" style={{ width: '70%', maxWidth: 750 }}>
          <div className="d-f ai-c mT-40">
            <div className="d-if ai-c">
              <Checkbox
                style={{ width: 100 }}
                checked={ !!placeholder?.global }
                onChange={ (e) => {
                  setPlaceholder({
                    ...placeholder,
                    global: e.target.checked
                  });
                } }
              >
                <span>Global</span>
                <span>
                  <Tooltip
                    className="mL-5"
                    placement="top"
                    title={ 'Shown on all domains' }
                  >
                    <QuestionCircleOutlined className="cur-p fsz-def text-ant-default" />
                  </Tooltip>
                </span>
              </Checkbox>
            </div>
            <div className="d-if">
              <Select
                style={{ width: 130 }}
                value={ placeholder?.status }
                onChange={ (status: 'DRAFT' | 'PUBLISHED') => {
                  setPlaceholder({
                    ...placeholder,
                    status: status
                  });
                } }
              >
                <Select.Option key={ 'draft' } value={ 'DRAFT' }>Draft</Select.Option>
                <Select.Option key={ 'published' } value={ 'PUBLISHED' }>Published</Select.Option>
              </Select>
            </div>
            <div className="d-if fx-1 mL-10">
              <Select
                mode={ 'multiple' }
                loading={ isLoadingTopics }
                style={{ minWidth: 300, width: '100%' }}
                placeholder={ 'Topics' }
                value={ !isLoadingTopics ? placeholder?.topic_ids : undefined }
                onChange={ (topic_ids: number[]) => {
                  setPlaceholder({
                    ...placeholder,
                    topic_ids: topic_ids
                  });
                } }
              >
                { topics.map((topic: ITopic, index: number) => (
                  <Select.Option key={ index } value={ topic.id }>{ topic.title }</Select.Option>
                ) ) }
              </Select>
            </div>
          </div>
          <div className="d-f ai-c mT-20">
            <Select
              mode="tags"
              loading={ isLoadingKeywords }
              style={{ minWidth: 300, width: '100%' }}
              placeholder="Keywords"
              defaultValue={ article?.keywords || [] }
              options={ keywords.map((keyword: string) => {
                return {
                  label: keyword,
                  value: keyword
                };
              } ) }
              onChange={ (keywords: string[]) => {
                setPlaceholder({
                  ...placeholder,
                  keywords: keywords
                });
              } }
              notFoundContent={ <span>Type to add new keyword</span> }
            />
          </div>
          <div className="d-f mT-20">
            <Input
              status={ !placeholder?.title ? 'error' : undefined }
              placeholder={ 'Title *' }
              value={ placeholder?.title }
              maxLength={ 50 }
              showCount
              onChange={ (event: React.BaseSyntheticEvent | null) => {
                setPlaceholder({
                  ...placeholder,
                  title: event?.target.value
                });
              } }
            />
          </div>
          <div className="mT-20">
            <Input.TextArea
              status={ !placeholder?.description ? 'error' : undefined }
              autoComplete="none"
              placeholder={ 'Description *' }
              autoSize={{ minRows: 2 }}
              value={ placeholder?.description }
              maxLength={ 250 }
              showCount
              onChange={ (event: React.BaseSyntheticEvent | null) => {
                setPlaceholder({
                  ...placeholder,
                  description: event?.target.value
                });
              } }
            />
          </div>
          <div className="d-f mT-10">
            <Input
              placeholder='Scribe URL: https://scribehow.com/embed/guide?as=scrollable'
              value={ placeholder?.scribe }
              onChange={ (event: React.BaseSyntheticEvent | null) => {
                setPlaceholder({
                  ...placeholder,
                  scribe: event?.target.value
                });
              } }
            />
          </div>
          { false &&
            <div className="d-f mT-20">
              <Input
                placeholder='Video URL: https://www.youtube.com/watch?v='
                value={ placeholder?.video }
                onChange={ (event: React.BaseSyntheticEvent | null) => {
                  setPlaceholder({
                    ...placeholder,
                    video: event?.target.value
                  });
                } }
              />
            </div>
          }
          <div className="Help-Article-Article-Upload">
            <Dragger
              style={{ color: '#000' }}
              name='file'
              multiple
              disabled={ isUploading }
              isImageUrl={ () => false }
              fileList={ placeholder?.attachments?.map((attachment: IAttachment): UploadFile => {
                return {
                  uid: `${attachment.id}`,
                  name: attachment.title,
                  status: 'done',
                };
              }) }
              beforeUpload={ () => false }
              onRemove={ (file: any) => {
                setPlaceholder({
                  ...placeholder,
                  attachments: placeholder.attachments?.filter((attachment: IAttachment) => `${attachment.id}` !== `${file.uid}`)
                });
                return false;
              } }
              onChange={ async ({ fileList }) => {
                try {
                  setIsUploading(true);

                  const form = new FormData();
                  fileList.forEach((file) => {
                    form.append('file', file.originFileObj as RcFile);
                  });

                  const file = await API.post(`/client/${client_id}/support/file/upload`, form);
                  const attachments = placeholder?.attachments || [];

                  if (file) {
                    attachments.push(file);
                  }

                  setPlaceholder({
                    ...placeholder,
                    attachments: attachments
                  });
                } catch (error) {
                  Notification('error', '', 'Failed to upload');
                } finally {
                  setIsUploading(false);
                }
              } }
            >
              <p className="ant-upload-drag-icon mB-0"><InboxOutlined /></p>
              <p className="ant-upload-hint">Attachments</p>
            </Dragger>
          </div>
        </div>
      </div>
    </CoverModal>
  );
};
