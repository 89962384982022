// Libs
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Containers
import SupportTicketContainer from 'containers/support/SupportTicketContainer';
import HelpArticleContainer from 'containers/support/HelpArticleContainer';

// Views
import NotFound from 'views/NotFound';

class SupportContainer extends React.Component {
  render = () => {
    return (
      <Switch>

        <Redirect exact from="/support" to="/support/help-articles" />

        <Route exact path="/support/support-tickets" component={ SupportTicketContainer } />
        <Route path="/support/support-tickets/:record_id" component={ SupportTicketContainer } />

        <Route exact path="/support/help-articles" component={ HelpArticleContainer } />
        <Route path="/support/help-articles/:record_id" component={ HelpArticleContainer } />

        <Route render={ () => <NotFound /> } />

      </Switch>
    );
  };
};

export default SupportContainer;
