// Libs
import { connect } from 'react-redux';
import classNames from 'classnames';
import React from 'react';
import _ from 'lodash';

// Components
import { Empty, Upload } from 'antd';
import BlockingSpinner from 'components/blocking-spinner';
import HelpArticleModal from 'views/support/help-articles/HelpArticleModal';

// Api
import { Api } from 'services/api';

// Interfaces
import AppState from 'store/AppState.interface';
import { Breadcrumb } from 'store/UI/State.interface';
import { ITopic, IArticle, IAttachment } from 'views/support/HelpArticles';
import { UploadFile } from 'antd/lib/upload/interface';
import { UserPermissions } from 'types/permissions';

// Actions
import { setBreadcrumbs } from "store/UI/ActionCreators";

// Icons
import { EditOutlined } from '@ant-design/icons';

interface Props {
  client_id: number,
  history: Record<string, any>;
  permissions: UserPermissions;
  match: {
    isExact: boolean;
    params: Record<string, any>;
    path: string;
    url: string;
  };
  setBreadcrumbs(breadcrumbs: Breadcrumb[], concat: boolean): void;
};

interface State {
  topics: ITopic[];
  article: IArticle | null;
  isFetching: boolean;
  isFetchingTopics: boolean;
  isLoadingVideo: boolean;
  showEditModal: boolean;
  isLoadingScribe: boolean;
  isDownloading: boolean;
};

const API: Api = new Api();

class HelpArticle extends React.Component<Props, State> {

  mounted: boolean = false;

  state: State = {
    topics: [],
    article: null,
    isFetching: false,
    isFetchingTopics: false,
    isLoadingVideo: false,
    showEditModal: this.props.history?.location?.state?.edit,
    isLoadingScribe: false,
    isDownloading: false,
  };

  componentDidMount = () => {
    const { client_id, match } = this.props;

    this.mounted = true;

    if (!!match?.params?.article_id) {
      this.fetchTopics(client_id);
      this.fetchArticle(client_id, match.params.article_id);
    }
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  fetchTopics = async (client_id: number) => {
    try {
      await new Promise((resolve) => this.setState({ isFetchingTopics: true }, () => resolve(null) ));
      const topics = await API.get(`client/${client_id}/support/article`);

      this.mounted && this.setState({
        topics: topics
      });
    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isFetchingTopics: false
      });
    }
  };

  fetchArticle = async (client_id: number, article_id: number) => {
    try {
      await new Promise((resolve) => this.setState({ isFetching: true }, () => resolve(null) ));

      const article = await API.get(`client/${client_id}/support/article/${article_id}`);

      if (this.mounted && !!article) {
        this.setState({
          article: article,
          isLoadingVideo: !!article?.video,
          isLoadingScribe: !!article?.scribe,
        }, () => {
          this.props.setBreadcrumbs([
            { title: 'Home', path: '/' },
            { title: 'Support', path: '/support' },
            { title: 'Help Articles', path: '/support/help-articles' },
            { title: article.title, path: '/support/help-articles' }
          ], false);
        });
      }
    } catch (error) {
      console.error('Error: ', error);
    } finally {
      this.mounted && this.setState({
        isFetching: false
      });
    }
  };

  handleDownload = async (file: { uid: string, name: string, url?: string | undefined }) => {
    const { client_id } = this.props;

    try {

      await new Promise((resolve) => this.setState({ isDownloading: true }, () => resolve(null) ));
      await API.download(`client/${client_id}/media/master/${file.url}`, file.name);

    } catch (error) {
      console.error(error);
    } finally {
      this.mounted && this.setState({
        isDownloading: false,
      });
    }
  };

  renderEditModal = (client_id: number, article: IArticle) => {
    return (
      <HelpArticleModal
        client_id={ client_id }
        article={ article }
        onOk={ () => {
          this.setState({
            showEditModal: false
          }, () => {
            this.fetchArticle(client_id, article.id);
            this.fetchTopics(client_id);
          });
        } }
        onClose={ () => this.setState({ showEditModal: false }) }
      />
    );
  };

  render = () => {
    const { client_id, permissions } = this.props;
    const { article, isFetching } = this.state;
    const canManage = !!permissions?.support_article_manage;

    if (isFetching) {
      return (
        <div className="d-f fxd-c">
          <div className="d-f ai-c jc-c" style={{ height: 'calc(100vh - 80px)' }}>
            <BlockingSpinner isLoading />
          </div>
        </div>
      );
    }

    if (!article) {
      return (
        <div className="d-f fxd-c">
          <div className="d-f ai-c jc-c" style={{ height: 'calc(100vh - 80px)' }}>
            <Empty description={ 'No articles found' } />
          </div>
        </div>
      );
    }

    return (
      <div className="d-f fxd-c fx-1 pos-r">
        { canManage && article.status === 'DRAFT' &&
          <div className="Help-Article-Status-Ribbon">{ article.status }</div>
        }
        { canManage &&
          <div
            className="link pos-a cur-p"
            style={{
              right: 50,
              bottom: 30,
              borderRadius: 20,
              padding: '5px 7px',
              borderWidth: 2,
              borderStyle: 'solid'
            }}
            onClick={ () => {
              this.setState({
                showEditModal: true
              });
            } }
          >
            <EditOutlined style={{ fontSize: 20 }} />
          </div>
        }
        <div className="d-f fxd-c fx-1">
          <div className="d-f ai-c fxd-c fx-1" style={{ backgroundColor: '#f8fafc' }}>
            <div className="d-f fxd-c fx-1 w-100p">
              <div className="d-f fx-1">
                <div className="d-f jc-sb w-100p">
                  <div
                    className="d-f fx-1 fxd-r"
                    style={{ minWidth: 500 }}
                  >
                    <div className="d-f fxd-c fx-1">
                      { !!article?.scribe ? (
                        <div className="d-f fxd-c fx-1">
                          { this.state.isLoadingScribe &&
                            <BlockingSpinner style={{ height: '100%' }} />
                          }
                          <iframe
                            className={ classNames("d-f fx-1", {
                              'd-n vis-h': this.state.isLoadingScribe
                            }) }
                            style={{ border: 0 }}
                            src={ article.scribe }
                            onLoad={ () => {
                              this.setState({
                                isLoadingScribe: false
                              });
                            } }
                          />
                        </div>
                      ) : (
                        <div className="d-f fxd-c fx-1 ai-c jc-c">
                          <Empty description />
                        </div>
                      ) }
                    </div>
                    { !_.isEmpty(article.attachments) &&
                      <div
                        className="mY-20 mX-25"
                        style={{ width: '30%', maxWidth: 300 }}
                      >
                        <div className='Help-Article-Attachments pY-15 pX-25'>
                          <div className="bdB fsz-md">
                            Attachments
                          </div>
                          <Upload
                            disabled
                            onPreview={ (file) => {
                              this.handleDownload(file);
                            } }
                            itemRender={ (originNode: React.ReactElement) => {
                              return (
                                <span className="cur-p">
                                  { originNode }
                                </span>
                              );
                            } }
                            fileList={ article.attachments.map((attachment: IAttachment): UploadFile => {
                              return {
                                uid: `${attachment.id}`,
                                name: attachment.title,
                                url: attachment.file_path,
                              };
                            } ) }
                          />
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        { this.state.showEditModal && this.renderEditModal(client_id, article) }
      </div>
    );
  };
};

const mapStateToProps = (store: AppState) => {
  return {
    client_id: store.ClientState.client_id,
    permissions: store.UserState.user.permissions,
  };
};

// Make functions available on props
const mapDispatchToProps = (dispatch: any) => {
  return {
    setBreadcrumbs: (value: Breadcrumb[], concat: boolean) => dispatch(setBreadcrumbs(value, concat)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpArticle);
