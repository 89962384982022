// Libs
import moment from 'moment';

// Services
import store from 'store/Store';
import { number_format } from 'utils/utils';

// Interface
import { UserEntity } from 'types/entities';

// Ant translations
import ant_en_GB from 'antd/es/locale/en_GB';
import ant_sv_SE from 'antd/es/locale/sv_SE';

// Assets
import locales from 'assets/lang/locales.json';
import internal_en_GB from 'assets/lang/en_GB.json';
import internal_sv_SE from 'assets/lang/sv_SE.json';
import currencies from 'assets/currencies.json';
import measurements from 'assets/measurements.json';

export const FIELD_DESCRIPTIONS: any = {
  'number_format': 'Sets the number format seen by default across the platform',
  'date_format': 'Sets how dates are presented across the platform',
  'locale': 'Sets the UI language for the platform',
  'currency': 'Sets the currency seen by default across the platform (subject to local defaults)',
  'measurement': 'Sets the measurement unit seen by default across the platform (subject to local defaults)',
  'active_session': 'Sets the duration a user can be inactive before the session ends',
  'gdpr': 'Click to view the GDPR Policy',
  'asset_maintenance_plan': 'Please select the maintenance plan being used by the system',
  'helpdesk_category_coa': 'Please select the COA category being used by the system to determine the helpdesk service',
  'financial_year_start_date': 'Set the beginning date of the financial year',
  'budget_auto_creation_offset': 'Set how many months in advance you would like PACS to auto-create next years budget',
  'cost_field_validation': 'Prevents cost field being saved if individual line items or total amount exceeds the available item value.',
  'results_per_page': 'Sets the default number of results per page',
  'owned_area_field': 'Sets owned property validation field for the space record',
  'lease_area_field': 'Sets lease property validation field for the space record',
  'default_reporting_period': 'Controls how users see time based reports by default',
};

// Constants
export const AVAILABLE_DATE_FORMATS = [
  'MM/DD/YY',
  'DD/MM/YY',
  'YY/MM/DD',
  'MM/DD/YYYY',
  'DD/MM/YYYY',
  'YYYY/MM/DD',
];

export const AVAILABLE_CURRENCIES: any = currencies;
export const AVAILABLE_MEASUREMENTS: any = measurements;

export const AVAILABLE_NUMBER_FORMATS: any = {
  'uk_standard': 'UK format (1,500.00)',
  'uk_without_thousand': 'UK without thousand separator (1500.00)',
  'eu_standard': 'European format (1.500,00)',
  'eu_without_thousand': 'European without thousand separator (1500,00)',
};
export const AVAILABLE_TIME_PERIODS: any = {
  '60': '1 Hour',
  '120': '2 Hours',
  '180': '3 Hours'
};
export const AVAILABLE_LOCALES = ['en-GB', 'sv-SE'];

export const FALLBACKS: any = {
  'date_format': 'DD/MM/YYYY',
  'locale': 'en-GB',
  'currency': 'GBP',
  'number_format': 'uk_standard',
  'measurement': 'SQM',
  'public_email': false,
  'public_phone': false,
  'email_mfa': false,
  'logotype': false,
  'active_session': '60',
  'cost_field_validation': 'Off',
  'results_per_page': '25'
};

export const COST_FIELD_VALIDATION_OPTIONS: any = {
  'off': 'Off',
  'total_only': 'Total Only',
  'line_by_line': 'Line By line'
};

export const getSetting = (settings: any, key: string) => {
  const setting = settings.find((_setting: any) => _setting.key === key);
  return !!setting ? setting.value : false;
};

export const getUserSetting = (key: string) => {
  const state = store.getState();
  const userSetting = state.UserState.user.settings.find((setting: any) => setting.key === key);
  const clientSetting = state.ClientState.settings.find((setting: any) => setting.key === key);

  if (userSetting) {
    return userSetting.value;
  } else if (clientSetting) {
    return clientSetting.value;
  }

  return FALLBACKS[key];
};

export const getClientSetting = (key: string) => {
  const state = store.getState();
  const clientSetting = state.ClientState.settings.find((setting: any) => setting.key === key);

  if (clientSetting) {
    return clientSetting.value;
  }

  return FALLBACKS[key];
};

export const getInternalLocale = (locale: string = FALLBACKS['locale']) => {
  switch (locale) {
    case 'en-GB':
      return internal_en_GB;
    case 'sv-SE':
      return internal_sv_SE;
  }
};

export const getAntLocale = (locale: string = FALLBACKS['locale']) => {
  switch (locale) {
    case 'en-GB':
      return ant_en_GB;
    case 'sv-SE':
      return ant_sv_SE;
  }
};

export const getLocaleName = (locale: string = FALLBACKS['locale']) => {
  // @ts-ignore
  return locales[locale].native;
};

export const getFormatedDate = (date: string, format: string = getUserSetting('date_format'), showTime: boolean = false): string => {
  if (showTime) {
    return moment(date).format(`${format} HH:mm`).toString();
  }
  return moment(date).format(`${format}`).toString();
};

export const getUserDateFormat = (user: UserEntity): string => {
  return user.settings.date_format || AVAILABLE_DATE_FORMATS[0];
};

export const useEmailMFA = () => {
  const state = store.getState();
  const userUseEmailMFA = state.UserState.user.settings.find((setting: any) => setting.key === 'email_mfa');;
  const clientUseEmailMFA = state.ClientState.settings.find((setting: any) => setting.key === 'email_mfa');

  if (userUseEmailMFA) {
    return userUseEmailMFA.value === 'true';
  } else if (clientUseEmailMFA) {
    return clientUseEmailMFA.value === 'true';
  }

  return FALLBACKS['email_mfa'];
};

export const getNumberFormatProps = (format: string = getUserSetting('number_format'), decimalScale: number = 2): any => {
  switch (format) {
    case 'uk_standard':
      return {
        thousandSeparator: ',',
        decimalSeparator: '.',
        fixedDecimalScale: true,
        decimalScale: decimalScale,
      };
    case 'uk_without_thousand':
      return {
        thousandSeparator: false,
        decimalSeparator: '.',
      };
    case 'eu_standard':
      return {
        thousandSeparator: '.',
        decimalSeparator: ',',
      };
    case 'eu_without_thousand':
      return {
        thousandSeparator: '.',
        decimalSeparator: ',',
      };
  }
};

export const getFormatedNumber = (value: string, format?: string | null, prefix?: string, suffix?: string) => {

  if (!format) {
    format = getUserSetting('number_format');
  }

  if (!prefix) {
    prefix = '';
  }

  if (!suffix) {
    suffix = '';
  }

  switch (format) {
    case 'uk_standard':
      return `${prefix}${number_format(value, 2, '.', ',')}${suffix}`;
    case 'uk_without_thousand':
      return `${prefix}${number_format(value, 2, '.', '')}${suffix}`;
    case 'eu_standard':
      return `${prefix}${number_format(value, 2, ',', '.')}${suffix}`;
    case 'eu_without_thousand':
      return `${prefix}${number_format(value, 2, ',', '')}${suffix}`;
  }
};
