export interface IProcess {
  user_id: number;
  id: number;
  response: IProcessResponse | null;
  status: IProcessStatus;
  job_batch: {
      id: string;
      name: string;
      total_jobs: number;
      pending_jobs: number;
      failed_jobs: number;
      cancelled_at: number | null;
      created_at: number;
      finished_at: number | null;
  };
};

export interface IProcessResponse {
  path: string;
  filename: string;
  message: string;
};

export enum IProcessStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  COMPLETE = 'COMPLETE',
  UNKNOWN = 'UNKNOWN',
};