// Libs
import React, { Component } from 'react';
import _ from "lodash";

// Components
import { Spin, Result, Typography, Modal } from 'antd';

// Interfaces
import { IProcess, IProcessResponse, IProcessStatus } from 'components/export/Export.interface';

// Services
import { Api } from 'services/api';
import Notification from 'services/notification';

// Icons
import { LoadingOutlined } from "@ant-design/icons";

interface Props {
  clientId: number;
  exportEndpoint: string;
  modalTitle: string;
  content: string;
  onClose: () => void;
};

interface State {
  process: IProcess | null;
  isExporting: boolean;
  isDownloading: boolean;
};

const API: Api = new Api();

export class ExportModal extends Component<Props, State> {

  intervalID: NodeJS.Timeout | null = null;
  mounted: boolean = false;

  state: State = {
    process: null,
    isExporting: false,
    isDownloading: false,
  };

  componentDidMount = async () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
    this.clearInterval();
  };

  componentDidUpdate = () => {
    if (!this.intervalID && this.state.process?.status === IProcessStatus.IN_PROGRESS) {
      this.intervalID = setInterval(this.poll, 5000);
    }
  };

  clearInterval = () => {
    if (this.intervalID) {
      clearInterval(this.intervalID);
      this.intervalID = null;
    }
  };

  poll = async () => {
    const { clientId } = this.props;
    const { process } = this.state;
    try {
      const response: IProcess = await API.get(`client/${clientId}/process/${process?.id}`);

      this.setState({
        process: response
      }, () => {
        if (response.status !== 'IN_PROGRESS') {
          this.clearInterval();
        }
      });
    } catch (error: any) {
      console.log(error);
    }
  };

  handleExport = async () => {
    const { exportEndpoint } = this.props;

    try {

      await new Promise((resolve) => this.setState({ isExporting: true }, () => resolve(null) ));

      const process = await API.get(exportEndpoint);

      this.setState({
        process: process
      });
    } catch (error) {
      Notification('error', '', 'Failed to export PDF');
    } finally {
      this.setState({
        isExporting: false
      });
    }
  };

  handleDownload = async (processResponse: IProcessResponse) => {
    const { clientId } = this.props;

    try {

      await new Promise((resolve) => this.setState({ isDownloading: true }, () => resolve(null) ));
      await API.download(`client/${clientId}/media/${processResponse.path}`, processResponse.filename);

    } catch (error) {
      Notification('error', '', 'Failed to download PDF');
    } finally {
      this.setState({
        isDownloading: false
      });
    }
  };

  renderExport = (process: IProcess | null) => {

    switch (process?.status) {
      case IProcessStatus.FAILED:
      case IProcessStatus.COMPLETE:
        return (
          <Result
            className='d-f jc-c ai-c fxd-c h-100p pY-0'
            status={ process?.status === IProcessStatus.FAILED ? 'error' : 'success' }
            title={ process?.status === IProcessStatus.FAILED ? 'Failed' : 'Completed' }
            extra={ [] }
          >
            <Typography.Paragraph className='m-0'>
              <Typography.Text className='fsz-sm'>
                { process?.response?.message || '' }
              </Typography.Text>
            </Typography.Paragraph>
          </Result>
        );
      case IProcessStatus.IN_PROGRESS:
        return (
          <div className="d-f jc-c ai-c fxd-c h-100p" style={{ minHeight: 150 }}>
            <Spin indicator={ <LoadingOutlined style={{ fontSize: 50 }} spin /> } />
            <span className="mT-40">{ 'Processing...' }</span>
          </div>
        );
      default:
        return (
          <div className="d-f ai-c" style={{ minHeight: 100 }}>
            <p>{ this.props.content }</p>
          </div>
        );
    }
  };

  render = () => {
    const { modalTitle, onClose } = this.props;
    const { process, isExporting, isDownloading } = this.state;
    const isLoading = process?.status === IProcessStatus.IN_PROGRESS || isExporting || isDownloading;

    return (
      <Modal
        visible
        centered
        title={ modalTitle || 'Export' }
        maskClosable={ false }
        closable={ false }
        okButtonProps={{
          disabled: isLoading,
          loading: isLoading,
        }}
        cancelButtonProps={{
          hidden: isLoading,
        }}
        cancelText={ 'Close' }
        okText={ process?.status === IProcessStatus.COMPLETE ? 'Download' : 'Export' }
        onCancel={ () => onClose() }
        onOk={ () => {
          if (process?.status === IProcessStatus.COMPLETE && !!process?.response) {
            this.handleDownload(process.response);
          } else {
            this.handleExport();
          }
        } }
      >
        <div>
          { this.renderExport(process) }
        </div>
      </Modal>
    );
  };
};