
/**
 * Polling Class, polls a provided function with a defined interval
 *
*/
export class Poll {
  interval: number = 1000;
  isPolling: boolean = false;
  intervalID: number | null = null;
  toPoll: Function;

  /**
   * Create an instance of the Polling Service
   * @param {Function} funcToPoll - The Function that will be polled
   * @param {number} [interval=1000] - The interval between calls, this is in `milliseconds`, defaults to `1000ms`
  */
  constructor(funcToPoll: Function, interval: number = 1000) {
    this.interval = interval;
    this.toPoll = funcToPoll;
  };

  /**
   * Starts the Polling Service
   */
  start() {
    this.intervalID = setInterval(this.toPoll, this.interval);
    this.isPolling = true;
  };

  /**
   * Terminates the Polling Service
   */
  terminate() {
    if (this.intervalID) {
      clearInterval(this.intervalID);
      this.intervalID = null;
      this.isPolling = false;
    }
  };

  /**
   * Returns the status of this current instance.
   * @returns boolean
   */
  get pollingStatus() {
    return this.isPolling;
  };
}
