// Libs
import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import _ from 'lodash';

// Components
import { Form, Input, Modal, Select } from 'antd';

// Interfaces
import { ElementTypes, EntityField, Field, Relation } from 'views/admin/entity/Entity.interfaces';

// Utils
import { transformFieldTitle } from 'utils/workflow';
import { orderListByKey } from 'utils/formSetup';

const RELATIONSHIP_TYPES = ['relationship', 'property_relationship', 'specification_relationship', 'space_relationship'];

enum Orientation {
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
};

interface Element {
  id: number;
  type: ElementTypes;
  hidden: boolean;
  config: any;
  dependencies: Array<any>;
};

interface Props {
  intl: IntlShape;
  clientId: number;
  mappedFields: EntityField[];
  fields: Field[];
  entityType: string;
  entityBundle: string;
  entityFields: EntityField[];
  relations: Relation[];
  onCancel: () => void;
  onSubmit: (type: string, config: any, hidden: boolean) => Promise<void>;
  isEditingElement?: boolean;
  initalState: Partial<Element>;
};

interface State {
  newElement: Partial<Element>;
  isAddingElement: boolean;
};

class AddElementDialog extends React.Component<Props, State> {
  mounted: boolean = false;

  state: State = {
    newElement: this.props.initalState,
    isAddingElement: false,
  };

  componentDidMount = async () => {
    this.mounted = true;
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  getTargets = (relationshipId: string): Array<{ bundle: string, type: string }> => {
    const { fields } = this.props;
    const field = fields.find((field) => field.id === relationshipId);
    if (field?.config?.target && _.isArray(field?.config?.target)) {
      return field?.config.target;
    }
    return [];
  };

  getRelationshipList = (relationId: string | undefined): Relation[] => {
    const { relations, fields, isEditingElement } = this.props;
    const field = fields.find(field => field.id === relationId);
    if (isEditingElement && !relations.find(relation => relation.id === relationId) && field) {
      return [...relations, field as Relation];
    }
    return relations;
  };

  getForeignRelationshipList = (): Field[] => {
    const { fields, entityType, entityBundle } = this.props;
    return fields.filter((field) =>
      field.entity_type === entityType &&
      field.entity_bundle === entityBundle &&
      RELATIONSHIP_TYPES.includes(field.type)
    );
  };

  getForeignFields = (fieldId: string): Field[] => {
    const { fields } = this.props;
    const targets = this.getTargets(fieldId);
    const filteredFields = fields.filter((field) =>
      targets.some((target) => field.entity_type === target.type && field.entity_bundle === target.bundle)
    );
    return _.uniqBy(filteredFields, 'id');
  };

  renderFieldForm = () => {
    const { mappedFields } = this.props;
    const { newElement } = this.state;
    return (
      <Form.Item label="Field" required>
        <Select
          showSearch
          value={ newElement.config?.field }
          placeholder={ 'Please choose the field' }
          onChange={ (fieldId: string) => {
            this.setState({ newElement: Object.assign(newElement, { config: { ...newElement.config, field: fieldId } }) });
          } }
          filterOption={ (input: any, option: any) => {
            return !!mappedFields.find((field: EntityField) => field.field_id === option.value &&
              (field.field_id.toLowerCase().includes(input.toLowerCase()) ||
                transformFieldTitle(field.field_id).toLowerCase().includes(input.toLowerCase())));
          } }
        >
          { orderListByKey(mappedFields, 'field_id').map((field: EntityField) => (
            <Select.Option key={ field.field_id } value={ field.field_id }>
              { transformFieldTitle(field.field_id) }
            </Select.Option>
          )) }
        </Select>
      </Form.Item>
    );
  };

  renderRelationForm = () => {
    const { entityFields } = this.props;
    const { newElement } = this.state;
    const relations = this.getRelationshipList(newElement.config?.field);
    const targets = relations.find(relation => relation.id === newElement.config?.field)?.config.target || [];

    const bundles: string[] = _.uniqBy(targets, 'bundle').map(target => target.bundle);
    const entityTypes: string[] = entityFields
      .filter((entityField) => entityField.field_id === newElement.config?.field && entityField.entity_bundle === newElement.config?.bundle)
      .map((entityField) => entityField.entity_type);

    return (
      <>
        <Form.Item label="Relationship" required>
          <Select
            showSearch
            value={ newElement.config?.field }
            placeholder={ 'Please choose the relationship' }
            onChange={ (fieldId: string) => {
              this.setState({
                newElement: Object.assign(newElement, {
                  config: { ...newElement.config, field: fieldId, bundle: undefined, type: undefined }
                })
              });
            } }
            filterOption={ (input: any, option: any) => {
              return !!relations.find(
                (relation: Relation) => relation.id === option.value && transformFieldTitle(relation.id).toLowerCase().includes(input.toLowerCase())
              );
            } }
          >
            { orderListByKey(relations, 'id').map((relation: Relation) => (
              <Select.Option key={ relation.id } value={ relation.id }>
                { transformFieldTitle(relation.id) }
              </Select.Option>
            )) }
          </Select>
        </Form.Item>
        { newElement.config?.field && (
          <Form.Item label="Bundle" required>
            <Select
              value={ newElement.config?.bundle }
              placeholder={ 'Please choose the bundle' }
              onChange={ (bundle: string) => {
                this.setState({
                  newElement: Object.assign(newElement, { config: { ...newElement.config, bundle: bundle, type: undefined } })
                });
              } }
            >
              { bundles.map((bundle: string) => (
                <Select.Option key={ bundle } value={ bundle }>
                  { transformFieldTitle(bundle) }
                </Select.Option>
              )) }
            </Select>
          </Form.Item>
        )}
        { newElement.config?.bundle && (
          <Form.Item label="Type" required>
            <Select
              showSearch
              value={ newElement.config?.type }
              placeholder={ 'Please choose the type' }
              onChange={ (type: string) => {
                this.setState({ newElement: Object.assign(newElement, { config: { ...newElement.config, type: type } }) });
              } }
              filterOption={ (input: any, option: any) => {
                return !!entityTypes.find((type: string) => type === option.value &&
                  (type.toLowerCase().includes(input.toLowerCase()) ||
                    transformFieldTitle(type).toLowerCase().includes(input.toLowerCase())));
              } }
            >
              { entityTypes.sort().map((type: string) => (
                <Select.Option key={ type } value={ type }>
                  { transformFieldTitle(type) }
                </Select.Option>
              )) }
            </Select>
          </Form.Item>
        )}
      </>
    );
  };

  renderForeignForm = () => {
    const { newElement } = this.state;
    const relations = this.getForeignRelationshipList();
    const fields = newElement.config?.relationship ? this.getForeignFields(newElement.config?.relationship) : [];

    return (
      <>
        <Form.Item label="Relationship" required>
          <Select
            showSearch
            value={ newElement.config?.relationship }
            placeholder={ 'Please choose the relationship' }
            onChange={ (relationId: string) => {
              this.setState({ newElement: Object.assign(newElement, { config: { ...newElement.config, relationship: relationId, field: undefined } }) });
            } }
            filterOption={ (input: any, option: any) => {
              return !!relations.find(
                (relation: Field) => relation.id === option.value && transformFieldTitle(relation.id).toLowerCase().includes(input.toLowerCase())
              );
            } }
          >
            { orderListByKey(relations, 'id').map((relation: Field) => (
              <Select.Option key={ relation.id } value={ relation.id }>
                { transformFieldTitle(relation.id) }
              </Select.Option>
            )) }
          </Select>
        </Form.Item>
        <Form.Item label="Field" required>
          <Select
            showSearch
            disabled={ !newElement.config?.relationship }
            value={ newElement.config?.field }
            placeholder={ 'Please choose the field' }
            onChange={ ( fieldId: string) => {
              this.setState({ newElement: Object.assign(newElement, { config: { ...newElement.config, field: fieldId } }) });
            } }
            filterOption={ (input: any, option: any) => {
              return !!fields.find((field: Field) => field.id === option.value &&
                (field.id.toLowerCase().includes(input.toLowerCase()) ||
                  transformFieldTitle(field.id).toLowerCase().includes(input.toLowerCase())));
            } }
          >
            { orderListByKey(fields, 'id').map((field: Field) => (
              <Select.Option key={ field.id } value={ field.id }>
                { transformFieldTitle(field.id) }
              </Select.Option>
            )) }
          </Select>
        </Form.Item>
        <Form.Item label="Label">
          <Input
            value={ newElement.config?.description }
            placeholder={ 'Please enter a label' }
            onChange={ (e) => {
              this.setState({ newElement: Object.assign(newElement, { config: { ...newElement.config, description: e.target.value } }) });
            } }
          />
        </Form.Item>
        <Form.Item label="Tooltip">
          <Input.TextArea
            value={ newElement.config?.tooltip }
            placeholder={ 'Please enter a tooltip' }
            rows={ 4 }
            onChange={ (e) => {
              this.setState({ newElement: Object.assign(newElement, { config: { ...newElement.config, tooltip: e.target.value } }) });
            } }
          />
        </Form.Item>
      </>
    );
  };

  renderDividerForm = () => {
    const { newElement } = this.state;
    return (
      <>
        <Form.Item label="Label">
          <Input
            value={ newElement.config?.text }
            placeholder={ 'Please enter a label' }
            onChange={ (e) => {
              this.setState({ newElement: Object.assign(newElement, { config: { ...newElement.config, text: e.target.value } }) });
            } }
          />
        </Form.Item>
        <Form.Item label="Label Positioning" required>
          <Select
            value={ newElement.config?.orientation }
            placeholder={ 'Please choose the label positioning' }
            onChange={ (orientation: Orientation) => {
              this.setState({ newElement: Object.assign(newElement, { config: { ...newElement.config, orientation: orientation } }) });
            } }
          >
            { Object.entries(Orientation).map(([title, value]) => (
              <Select.Option key={ value } value={ value }>
                { title }
              </Select.Option>
            )) }
          </Select>
        </Form.Item>
      </>
    );
  };

  renderEmptyForm = () => {
    const { newElement } = this.state;
    return (
      <Form.Item label="Label" required>
        <Input
          value={ newElement.config?.description }
          placeholder="Please enter a label"
          onChange={ (e) => {
            this.setState({ newElement: Object.assign(newElement, { config: { ...newElement.config, description: e.target.value } }) });
          } }
        />
      </Form.Item>
    );
  };

  render = () => {
    const { onSubmit, onCancel, isEditingElement } = this.props;
    const { newElement, isAddingElement } = this.state;
    const showColumnSpan = newElement.type === ElementTypes.Field || newElement.type === ElementTypes.Foreign;

    const elementTypes: Array<{ title: string; value: ElementTypes }> = Object.entries(ElementTypes)
      .filter(([__, value]) => value !== ElementTypes.View)
      .map(([title, value]) => ({ title, value }));

    const onSubmitHandler = () => {
      this.mounted && this.setState({ isAddingElement: true }, async () => {
        try {
          const { type = '', config, hidden = false } = newElement;
          const processedConfig = showColumnSpan ? config : { ...config, column_span: 12 };
          await onSubmit(type, processedConfig, hidden);
        } catch (error) {
          console.error('Error: ', error);
        } finally {
          this.mounted && this.setState({
            isAddingElement: false,
            newElement: {},
          });
          onCancel();
        }
      });
    };

    const isOkButtonDisabled = () => {
      const { type, config } = newElement;

      if (!type || (showColumnSpan && !config?.column_span) || isAddingElement) {
        return true;
      }

      if (type === ElementTypes.Field && !config?.field) {
        return true;
      }

      if (type === ElementTypes.Relation && (!config?.field || !config?.bundle || !config?.type)) {
        return true;
      }

      if (type === ElementTypes.Foreign && (!config?.relationship || !config?.field)) {
        return true;
      }

      if (type === ElementTypes.Divider && !config?.orientation) {
        return true;
      }

      if (type === ElementTypes.Empty && !config?.description?.trim()) {
        return true;
      }

      return false;
    };

    return (
      <Modal
        title={ isEditingElement ? 'Edit Element' : 'Add Element' }
        maskClosable={ !isAddingElement }
        centered
        visible
        onCancel={ () => this.mounted && this.setState({ newElement: {} }, onCancel) }
        onOk={ onSubmitHandler }
        okText={ isEditingElement ? 'Save' : 'Create' }
        okButtonProps={{
          disabled: isOkButtonDisabled(),
          loading: isAddingElement,
        }}
        cancelButtonProps={{
          disabled: isAddingElement,
        }}
      >
        <Form layout="vertical">
          <Form.Item label="Type" required>
            <Select
              showSearch
              value={ newElement.type }
              placeholder={ 'Please choose the element type' }
              disabled={ isEditingElement }
              onChange={ (type: ElementTypes) => this.setState({ newElement: Object.assign(newElement, { type: type, config: {} }) }) }
              filterOption={ (input: any, option: any) => {
                return !!elementTypes.find(
                  ({ title, value }) => value === option.value && title.toLowerCase().includes(input.toLowerCase())
                );
              } }
            >
              { orderListByKey(elementTypes, 'title').map(({ title, value }, index: number) => (
                <Select.Option key={ value + index } value={ value }>
                  { title }
                </Select.Option>
              )) }
            </Select>
          </Form.Item>
          { newElement.type === ElementTypes.Field && this.renderFieldForm() }
          { newElement.type === ElementTypes.Relation && this.renderRelationForm() }
          { newElement.type === ElementTypes.Foreign && this.renderForeignForm() }
          { newElement.type === ElementTypes.Divider && this.renderDividerForm() }
          { newElement.type === ElementTypes.Empty && this.renderEmptyForm() }
          { showColumnSpan && (
            <Form.Item label="Field Width" required>
              <Select
                showSearch
                value={ newElement.config?.column_span }
                placeholder={ 'Please choose column span' }
                onChange={ (columnSpan: number) => {
                  this.setState({ newElement: Object.assign(newElement, { config: { ...newElement.config, column_span: columnSpan } }) });
                } }
              >
                { Array(12).fill(1).map((__, index) => (
                  <Select.Option key={ index + 1 } value={ index + 1 }>
                    { index + 1 }
                  </Select.Option>
                )) }
              </Select>
            </Form.Item>
          ) }
        </Form>
      </Modal>
    );
  };
};

export default injectIntl(AddElementDialog);
